/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Filters from './filters/Filters';

const ViewUnit = () => (
  <div className="findComparisonContainer">
    <Filters />
  </div>
);

ViewUnit.defaultProps = {
};
export default ViewUnit;
