/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import IcOutbox from '@ingka/ssr-icon/paths/arrow-cloud-in';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import IcBackArrow from '@ingka/ssr-icon/paths/arrow-left';
import { useNavigate } from 'react-router-dom';
import styles from './AppBarMobile.module.css';
import { useTranslate } from '../../services/translationService.js';
import HamburgerMobile from '../hamburgerMobile/HamburgerMobile';
import { appRoutes } from '../../constants/constant';
import { offlineData } from '../../configs/offlineData';
import {
  isIos,
  isNullEmptyOrUndefined, isSu,
} from '../../services/commonService.js';

interface AppBarMobileProps {
  source: 'dashboard' | 'other';
  title?: string;
}

const AppBarMobile = ({ title, source }: AppBarMobileProps) => {
  const applicationTitle = useTranslate('application.title');
  const navigate = useNavigate();
  const [outboxCount, setOutboxCount] = useState(0);
  const [unit, setUnit] = useState('');

  const fetchOutboxCount = () => {
    const count = localStorage?.getItem('outboxCount') || '0';
    setOutboxCount(parseInt(count, 10));
  };

  const fetchUnit = (sellingUnit?: string) => {
    offlineData.getItem('selectedRetailUnit').then((selectedRu: any) => {
      if (!isNullEmptyOrUndefined(selectedRu) && isSu()) {
        setUnit(`Selling unit: ${selectedRu?.ruCode} - ${sellingUnit}`);
      }
    });
  };

  useEffect(() => {
    fetchOutboxCount();
    offlineData.getItem('userSellingUnits').then((data: any) => {
      offlineData.getItem('userRetailUnits').then((userRetailData: any) => {
        if (userRetailData?.length > 0) {
          const userSuName = data?.filter((val:any) => val.unitCode === userRetailData[0]);
          if (isIos() !== true && userSuName) fetchUnit(userSuName[0]?.unitName);
        }
      });
    });
  }, []);

  const renderDashboardAppBar = () => (
    <>
      <div className={styles.headerLeftWrapper}>
        <HamburgerMobile unit={unit} />
        <Text className={styles.applicationTitle}>
          {applicationTitle}
        </Text>
      </div>
      <Button
        className={styles.icOutbox}
        href=""
        iconOnly
        ssrIcon={IcOutbox}
        onClick={() => navigate(appRoutes.outbox)}
        type="tertiary"
      >
        {outboxCount && outboxCount > 0 ? (
          <Text className={styles.dbmOutboxCount}>{outboxCount}</Text>
        ) : null}
      </Button>
    </>
  );

  const renderOtherAppBar = () => (
    <div className={styles.otherAppbarWrapper}>
      <Button
        className={styles.mobileBackButton}
        iconOnly
        small
        type="tertiary"
        onClick={() => navigate(appRoutes.dashboard)}
      >
        <SSRIcon paths={IcBackArrow} />
      </Button>
      <Text className={styles.otherTitle}>
        {title}
      </Text>
    </div>
  );

  const buildContent = () => {
    switch (source) {
      case 'dashboard':
        return renderDashboardAppBar();
      case 'other':
        return renderOtherAppBar();
      default:
        return null;
    }
  };

  return (
    <header className={styles.headerContainer} key="AppBarMobile">
      {buildContent()}
    </header>
  );
};

AppBarMobile.defaultProps = {
  title: null,
};

export default AppBarMobile;
