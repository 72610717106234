/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';
import { useTranslate } from '../../../../../common/services/translationService.js';

interface PillsToolbarProps {
  data: any;
  hfbData: any;
  onRemoveFilter: (newPayload: any, newHfbData: any) => void;
  onClearAll: () => void;
}

enum FilterType {
  hfb, pra, pa, itemType, roc, sp, pc, cs
}

const PillsToolbar = ({
  data,
  hfbData,
  onRemoveFilter,
  onClearAll,
}: PillsToolbarProps) => {
  const removeItemFromArray = (arr:any[], value: any) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const removeFilter = (filterVal: any, type: FilterType) => {
    const tempPayload = { ...data };
    let tempHfbData = [...hfbData];
    switch (type) {
      case FilterType.hfb:
        tempPayload.HfbId = removeItemFromArray(tempPayload?.HfbId, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.pra:
        tempPayload.PraId = removeItemFromArray(tempPayload?.PraId, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.pa:
        tempPayload.PaId = removeItemFromArray(tempPayload?.PaId, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      default: break;
    }
    onRemoveFilter(tempPayload, tempHfbData);
  };

  const renderPills = (filterList: any[], type: FilterType) => filterList?.map((filter: any) => (
    <Pill
      key={`${type}${filter}`}
      className={`${styles.pill} ${styles.pillBorder} noselect`}
      label={filter}
      small
      ssrIcon={IcCross}
      onClick={() => removeFilter(filter, type)}
    />
  ));

  return (
    <div className={styles.pillsContainer}>
      {data?.HfbId?.length > 0 ? (
        renderPills(data?.HfbId, FilterType.hfb)
      ) : null}
      {data?.PraId?.length > 0 ? (
        renderPills(data?.PraId, FilterType.pra)
      ) : null}
      {data?.PaId?.length > 0 ? (
        renderPills(data?.PaId, FilterType.pa)
      ) : null}
      <Pill
        className={`${styles.pill} noselect`}
        label={useTranslate('find.comp.clearAll')}
        small
        onClick={onClearAll}
      />
    </div>
  );
};

export default PillsToolbar;
