/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import { useLocation } from 'react-router-dom';
import classes from './RenderEmpty.module.css';
import { useTranslate } from '../../services/translationService.js';
import { appRoutes } from '../../constants/constant';

const RenderEmpty = ({
  empText, icon, subText, smallType,
}: {
  empText: string;
  icon: any;
  subText?: boolean;
  smallType?:boolean;
}) => {
  const location = useLocation();
  const subtext1 = location.pathname === appRoutes.FC ? useTranslate('render.empty.fcSubText1')
    : location.pathname === appRoutes.viewUnit ? useTranslate('render.empty.fcSubText1') : useTranslate('render.empty.subtext1');
  const subtext2 = location.pathname === appRoutes.FC ? useTranslate('render.empty.fcSubText2')
    : location.pathname === appRoutes.viewUnit ? useTranslate('render.empty.fuSubText2') : useTranslate('render.empty.subtext2');
  const subtext3 = (location.pathname === appRoutes.FC || location.pathname === appRoutes.viewUnit) ? null : useTranslate('render.empty.subtext3');
  return (
    <div className={`${classes.emptyData} ${smallType ? classes.emptyDataSmall : ''}`}>
      <SSRIcon
        className={smallType
          ? classes.emptyDataIconSmall
          : classes.emptyDataIcon}
        paths={icon}
      />
      <Text
        className={smallType ? classes.emptyMainTextSmall : classes.emptyMainText}
        headingSize="xl"
        tagName="h1"
      >
        <b>{empText}</b>
      </Text>
      {subText && (
      <div className={classes.emptySubText}>
        <span>{subtext1}</span>
        <span>{subtext2}</span>
        <span>{subtext3}</span>
      </div>
      )}
    </div>
  );
};

RenderEmpty.defaultProps = {
  subText: false,
  smallType: false,
};
export default RenderEmpty;
