/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import Modal, {
  ModalBody, ModalFooter, ModalHeader, Sheets,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import React from 'react';
import plus from '@ingka/ssr-icon/paths/plus';
import Text from '@ingka/text';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { useNavigate } from 'react-router-dom';
import './ItemDetailsMobile.css';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslate } from '../../../../common/services/translationService.js';
import { appRoutes } from '../../../../common/constants/constant';
import { getFullUrl, isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

interface ItemDetailsMobileProps {
  openItemModal: boolean;
  selectedItem: any;
  isInstantComparison?: boolean;
  onCloseItemModal: () => void;
}

const ItemDetailsMobile = ({
  openItemModal, selectedItem, isInstantComparison,
  onCloseItemModal,
}: ItemDetailsMobileProps) => {
  const navigate = useNavigate();
  const idmItemNo = useTranslate('tab.items.item');
  const idmPrice = useTranslate('common.price');
  const idmHfb = useTranslate('customUnit.overview.hfb');
  const idmPa = useTranslate('tab.items.paNo');
  const idmReferenceUrl = useTranslate('customUnit.overview.referenceUrl');
  const idmComments = useTranslate('customUnit.details.commentsLabel');
  const idmDimensionMetric = useTranslate('itemDetails.dimensionMetric');
  const idmMaterial = useTranslate('itemDetails.material');
  const idmDimensionImperial = useTranslate('itemDetails.dimensionImperial');

  const carouselProps = {
    id: 'ItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const renderAccordionItems = (title: string, data: string, type?: string) => (
    <div key={`${type}${title}${data}`} className={type ? 'flexColumn itemContWidth' : 'flexRow itemContWidth'}>
      <Text className="item-date">
        {title}
        {title?.includes(':') ? '' : ':'}
      </Text>
      <Text className="itemSubTitle">{data}</Text>
    </div>
  );

  const renderTabItems = (title: string, data: string) => (
    <>
      <div className="idmSection">
        <Text className="idmLabel">{title}</Text>
        <Text className="idmValue">{data}</Text>
      </div>
      <Divider type="vertical" className="idmDivider" />
    </>
  );

  return (
    <Modal
      visible={openItemModal}
      handleCloseBtn={() => onCloseItemModal()}
    >
      <Sheets
        aria-labelledby="itemDetailModal"
        alignment="right"
        fullSize
        size="small"
        header={(
          <ModalHeader
            className="defaultFont"
            titleId="itemDetailModal"
            title="Item Details"
            floating={false}
          />
          )}
        footer={(
          isInstantComparison !== false && (
          <ModalFooter>
            <Button
              text="Add Comparison"
              type="emphasised"
              small={false}
              ssrIcon={plus}
              disabled={
                dayjs(selectedItem?.itemInfos?.deadlineDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
                || dayjs(selectedItem?.itemInfos?.itemStartDate).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')
              }
              onClick={() => {
                navigate(
                  appRoutes.IC,
                  { state: { isInstant: false, selectedItem, from: 'itemDetails' } },
                );
                onCloseItemModal();
              }}
            />
          </ModalFooter>
          ))}
      >
        <ModalBody>
          <Carousel {...carouselProps}>
            <img key="item-img" className="item-image" src={selectedItem?.itemInfos?.imgData} alt="" />
          </Carousel>
          <Text className="item-title">{selectedItem?.itemName}</Text>
          {selectedItem?.itemType === 'CST' ? (
            <>
              <div className="cuContainer">
                <div className="idmSectionWrapper idmTopWrapper">
                  {renderTabItems(idmItemNo, selectedItem?.itemInfos?.itemNo)}
                  {renderTabItems(idmPrice, !isNullEmptyOrUndefined(selectedItem?.itemInfos?.priceInfo?.priceInclTax) ? `${selectedItem?.itemInfos?.priceInfo?.priceInclTax} ${selectedItem?.itemInfos?.priceInfo?.currencyCode}` : `${0} EUR`)}
                  {renderTabItems(idmHfb, selectedItem?.itemInfos?.customUnit?.hfbNo)}
                  {renderTabItems(idmPa, selectedItem?.itemInfos?.customUnit?.paNo)}
                  {selectedItem?.comparisonItemBasicViewEntity?.isBti === true ? (
                    <BtiComponent ml />
                  ) : null}
                </div>
                <div className="itemDetailSectionWrapper idmMidWrapper">
                  {!isNullEmptyOrUndefined(selectedItem?.itemInfos?.customUnit?.unitUrl) ? (
                    <div className="idmSection">
                      <Text className="idmLabel">{idmReferenceUrl}</Text>
                      <div className="idmValue idmUrl">
                        <a href={getFullUrl(selectedItem?.itemInfos?.customUnit?.unitUrl)} target="_blank" rel="noopener noreferrer">
                          {selectedItem?.itemInfos?.customUnit?.unitUrl}
                        </a>
                      </div>
                    </div>
                  )
                    : null}
                </div>
              </div>
              <Accordion>
                <AccordionItem
                  id="itemDetailsComments"
                  title={idmComments}
                  open
                >
                  <Text className="itemDetails-measure-value">{selectedItem?.itemInfos?.customUnit?.comment ? selectedItem?.itemInfos?.customUnit?.comment : ''}</Text>
                </AccordionItem>
              </Accordion>
            </>
          ) : (
            <>
              <div className="itemDetailsMobile-subTitle-container">
                <Text className="idmSectionSubTitle">
                  {`${selectedItem?.paNo}  •  ${selectedItem?.itemNo}${!isNullEmptyOrUndefined(selectedItem?.itemInfos?.priceInfo?.priceInclTax) ? `  •  ${selectedItem?.itemInfos?.priceInfo?.currencyCode} ${selectedItem?.itemInfos?.priceInfo?.priceInclTax}` : ''}`}
                </Text>
                {selectedItem?.isBti === true ? (
                  <div style={{ display: 'flex', marginLeft: '8px', alignItems: 'center' }}>
                    <Text className="idmSectionSubTitle">{'  •'}</Text>
                    <BtiComponent ml />
                  </div>
                ) : null}
              </div>
              <div className="idmDateWrapper">
                <Text className="idmSectionSubTitle">Deadline</Text>
                <Text className="item-date">
                  {selectedItem?.itemInfos?.deadlineDate}
                </Text>
              </div>
              <Accordion>
                <AccordionItem
                  id="itemDetailsDimensionMetric"
                  title={idmDimensionMetric}
                >
                  {selectedItem?.itemInfos?.metricMeasures?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value),
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion>
                <AccordionItem
                  id="itemDetailsMaterial"
                  title={idmMaterial}
                >
                  {selectedItem?.itemInfos?.materials?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value, 'mat'),
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion>
                <AccordionItem
                  id="itemDetailsDimensionImperial"
                  title={idmDimensionImperial}
                >
                  {selectedItem?.itemInfos?.imperialMeasures?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value),
                  )}
                </AccordionItem>
              </Accordion>
            </>
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

ItemDetailsMobile.defaultProps = {
  isInstantComparison: 'true',
};

export default ItemDetailsMobile;
