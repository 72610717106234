/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import { Steps, UploadFile } from 'antd';
import './InstantComparison.css';
import Button from '@ingka/button';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import TopNavigationBar from './topNavigation/TopNavigation';
import styles from './InstantComparison.module.css';
import {
  isNullEmptyOrUndefined, projectType, userData,
  isNullEmptyUndefinedOrZero,
  convertUnit,
  convertToBase64,
  isIos,
  userRuDetails,
} from '../../../common/services/commonService.js';
import NumberOfPieces from './numberOfPieces/NumberOfPieces';
import CompetitorSection from './competitorSection/CompetitorSection';
import CompetitorPrice from './competitorPrice/CompetitorPrice';
import ItemQualityFunctionality from './itemQualityFunctionality/ItemQualityFunctionality';
import { RetailUnit } from '../../desktop/createProject/retailUnitsTab/RetailUnits';
import OptionalFields from './optionalFields/OptionalFields';
import TroStartDate from './troStartDate/TroStartDate';
import TroEndDate from './troEndDate/TroEndDate';
import TroRegularPrice from './troRegularPrice/TroRegularPrice';
import { offlineData } from '../../../common/configs/offlineData';
import PhotoUpload from './photoUpload/PhotoUpload';
import ItemAssembled from './itemAssembled/ItemAssembled';
import Summary from './summary/Summary';
import { useTranslate } from '../../../common/services/translationService.js';
import CloseModal from '../../../common/components/closeModal/CloseModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';
import {
  ComparisonTextArea, appRoutes, checkZeroRegex, compNoOfPieces, compPriceDiff,
} from '../../../common/constants/constant';
import ItemConnectionWarning from './itemConnectionWarning/ItemConnectionWarning';
import RuSection from './ruSection/ruSection';

interface SectionTitleProps {
  sectionTitle: string;
  customClass?: string | null;
}

export const SectionTitle = ({
  sectionTitle, customClass,
}: SectionTitleProps) => (
  <span className={`${styles.mobileSectionTitle} ${customClass}`}>{sectionTitle}</span>
);

SectionTitle.defaultProps = {
  customClass: null,
};

export const SectionDescription = ({ sectionDescription }: { sectionDescription: string }) => (
  <span className={styles.mobileSectionDescription}>{sectionDescription}</span>
);

const piecesUnit = {
  groupName: 'MET',
  key: 'pc',
  value: 'pieces',
};

const InstantComparison = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [currentStep, setCurrentStep] = useState(isIos() ? 0 : 1);
  const [selectedCompetitorId, setSelectedCompetitorId] = useState<number | string>();
  const [selectedCompetitor, setSelectedCompetitor] = useState<string | number>();
  const [competitorList, setCompetitorsList] = useState<any>([]);
  const [competitorPrice, setCompetitorPrice] = useState<string>();
  const [regularPrice, setRegularPrice] = useState<string>();
  const [currency, setCurrency] = useState();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [unknownRegularPrice, setUnknownRegularPrice] = useState<boolean>(false);
  const [unknownStartDate, setUnknownStartDate] = useState<boolean>(false);
  const [unknownEndDate, setUnknownEndDate] = useState<boolean>(false);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [showTimeRestricted, setShowTimeRestricted] = useState<boolean>(false);
  const [material, setMaterial] = useState<string>();
  const [sustainability, setSustainability] = useState<string>();
  const [comment, setComment] = useState<string>();
  const [competitorItemName, setCompetitorItemName] = useState<string>();
  const [competitorItemUrl, setCompetitorItemUrl] = useState<string>();
  const [urlError, setUrlError] = useState<boolean>(false);
  const [ikeaItemMetric, setIkeaItemMetric] = useState<string>('');
  const [compItemMetric, setcompItemMetric] = useState<string>('');
  const [itemQuality, setItemQuality] = useState<string>();
  const [itemFunctionality, setItemFunctionality] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [itemAssembled, setItemAssembled] = useState<boolean>();
  const [fromSummary, setFromSummary] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const saveComparisonText = useTranslate('inst.comp.saveComparison');
  const updateComparisonText = useTranslate('inst.comp.updateComparison');
  const deleteCompMsg = useTranslate('outbox.del.success');
  const deleteNoCompMsg = useTranslate('outbox.del.nc.success');
  const delNocompText = useTranslate('outbox.del.nc.subtitle');
  const delCompText = useTranslate('outbox.del.subtitle');
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.createProjReducer.isLoading);
  const checkFieldSelected = isNullEmptyOrUndefined(selectedCompetitor);
  const [comparisonList, setComparisonList] = useState<any>([]);
  const [openItemConnectionWarningModal, setOpenItemConnectionWarningModal] = useState<any>(false);
  const [isInstant, setIsInstant] = useState<boolean>(false);
  const selectedItem: any = location.state?.selectedItem;
  const isNoCompetition: any = location.state?.isNoCompetition;
  const dontShowConnectedItemWarning = localStorage.getItem('dontShowConnectedItemWarning');
  const [openPricePopupSave, setOpenPricePopupSave] = useState<boolean>(false);
  const [isFoodhfb, setIsFoodhfb] = useState<boolean>(false);
  const [isPriceunit, setIsPriceunit] = useState<boolean>(false);
  const [masterUnitList, setMasterUnitList] = useState<any>([]);
  const [foodHfbUnitList, setFoodHfbUnitList] = useState<any>([]);
  const [itemQuantity, setItemQuantity] = useState<number>();
  const [quantityError, setQuantityError] = useState<boolean>(false);
  const [itemPaNo, setItemPaNo] = useState<string>();
  const [itemNo, setItemNo] = useState<string>();
  const [ikeaPriceUnitTypeNo, setIkeaPriceUnitTypeNo] = useState<string>();
  const [ikeaPriceUnitType, setIkeaPriceUnitType] = useState<string>();
  const [ikeaItemPrice, setIkeaItemPrice] = useState<number>();
  const [ikeaItemQuantity, setIkeaItemQuantity] = useState<number>();
  const [comparisonData, setComparisonData] = useState<any>();
  const [maliciousURLError, setMaliciousURLError] = useState<boolean>(false);
  const [maliciousFileError, setMaliciousFileError] = useState<boolean>(false);
  const [noCompDeletePc, setNoCompDeletePc] = useState<boolean>(false);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [isNocomp, setIsNoComp] = useState(false);
  const [disableNoComp, setDisableNoComp] = useState<boolean>(false);
  const [itemForEdit, setItemForEdit] = useState<any>(null);
  const navSrc = location?.state?.from;
  const compIndexFrmOutbox = location?.state?.compIndex;

  const navigateTo = () => {
    (isInstant === false && isEdit !== true)
      ? navigate(appRoutes.projDetails)
      : isEdit === true ? navigate(appRoutes.outbox) : navigate(appRoutes.dashboard);
  };

  const removeSelectedRuForIos = () => {
    isIos() && offlineData.removeItem('selectedRetailUnit');
  };

  const handleCloseModal = () => {
    if (!checkFieldSelected) {
      setOpenCloseModal(true);
    } else {
      removeSelectedRuForIos();
      navigateTo();
    }
  };

  const fetchCurrency = (ruCode: string) => {
    offlineData.getItem('currencyList').then((currencyList: any) => {
      if (currencyList?.length > 0) {
        const currencyCode = currencyList.find((item: any) => item.key === ruCode);
        setCurrency(currencyCode?.value);
      }
    });
  };

  const editItemCriteria = (field: string | undefined) => {
    switch (field) {
      case '10':
        return 'better';
      case '20':
        return 'equal';
      case '30':
        return 'worse';
      default:
        return undefined;
    }
  };

  const editItemAssemblyYorN = (field: string | null) => {
    if (field === '80') {
      return true;
    }
    if (field === '100') {
      return false;
    }
    return undefined;
  };

  const editInfoCollectedOnlineYorN = (field: string | null) => {
    switch (field) {
      case '1':
        return true;
      case '0':
        return false;
      default:
        return undefined;
    }
  };

  const isPieces = () => compItemMetric === piecesUnit.key;

  useEffect(() => {
    if (isEdit) {
      const comparisonIndexStr = localStorage.getItem('selectedComparison');
      if (comparisonIndexStr) {
        const comparisonIndex = parseInt(comparisonIndexStr, 10);
        const comparison = comparisonList[comparisonIndex];
        setComparisonData(comparison);
        if (comparison && Object.keys(comparison)?.length !== 0) {
          setSelectedCompetitor(comparison.CompetitorName);
          setSelectedCompetitorId(comparison.CompetitorId);
          setItemPaNo(comparison?.PaNo);
          setItemNo(comparison?.ItemNo);
          setItemForEdit(comparison?.item || null);
          if (comparison.UserId === userData().userId) {
            setSelectedRU(comparison?.selectedRu || null);
          } else setSelectedRU(userRuDetails() || null);
          setIsNoComp(comparison.NoCompetition === 1);
          if (comparison.NoCompetition !== 1) {
            setItemQuantity(isNullEmptyOrUndefined(comparison?.CompPriceUnitText) ? comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '20').CriteriaValue : comparison.CompPriceUnitValue);
            setCompetitorPrice(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '30').CriteriaValue);
            setShowTimeRestricted(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '210').CriteriaValue === 'true');
            const editRegularPrice = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '60').CriteriaValue;
            if (editRegularPrice === true) {
              setUnknownRegularPrice(editRegularPrice);
            } else {
              editRegularPrice !== false && setRegularPrice(editRegularPrice);
            }
            const editStartDate = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '40').CriteriaValue;
            if (typeof editStartDate === 'boolean') {
              editStartDate === true && setUnknownStartDate(editStartDate);
            } else {
              !isNullEmptyOrUndefined(editStartDate) ? setStartDate(dayjs(editStartDate, 'YYYY-MM-DD')) : setStartDate(null);
            }
            const editEndDate = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '50').CriteriaValue;
            if (typeof editEndDate === 'boolean') {
              editEndDate === true && setUnknownEndDate(editEndDate);
            } else {
              !isNullEmptyOrUndefined(editEndDate) ? setEndDate(dayjs(editEndDate, 'YYYY-MM-DD')) : setEndDate(null);
            }
            const editItemQuality = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '70').CriteriaValue;
            setItemQuality(editItemCriteria(editItemQuality));
            const editItemFunctionality = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '80').CriteriaValue;
            setItemFunctionality(editItemCriteria(editItemFunctionality));
            const editItemAssembled = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '90').CriteriaValue;
            setItemAssembled(editItemAssemblyYorN(editItemAssembled));
            setFileList(comparison.ImageList);
            setComment(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '170').CriteriaValue || '');
            setSustainability(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '180').CriteriaValue || '');
            setMaterial(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '190').CriteriaValue || '');
            setCompetitorItemName(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '200').CriteriaValue || '');
            setCompetitorItemUrl(comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '220').CriteriaValue || '');
            const checkIsInstant = (comparison?.IsInstantCompare === 1);
            setIsInstant(checkIsInstant);
            setIsFoodhfb(comparison?.IsFoodhfb);
            setIsPriceunit(comparison?.IsPriceunit);
            setFoodHfbUnitList(comparison?.ValidUnits);
            setcompItemMetric(isNullEmptyOrUndefined(comparison?.CompPriceUnitText) ? piecesUnit.key : comparison?.CompPriceUnitText);
            setIkeaPriceUnitType(comparison?.PriceUnitType);
            setIkeaPriceUnitTypeNo(comparison?.PriceUnitTypeNo);
            setIkeaItemPrice(comparison?.ItemDetails?.priceInfo?.priceInclTax);
            setIkeaItemMetric(comparison?.IsPieces === false ? comparison?.IkeaPriceUnitText : piecesUnit.key);
            setIkeaItemQuantity(comparison?.IsPieces === false ? comparison?.IkeaItemQuantity : comparison?.ItemDetails?.packageQuantity);
            setMaliciousURLError(comparison?.urlError || false);
            setMaliciousFileError(comparison?.fileError || false);
            setCurrentStep(12);
          } else {
            setCurrentStep(isIos() ? 0 : 1);
          }
        }
      }
    }
  }, [comparisonList]);

  const sortLatestCompetitors = (savedComps: any) => {
    offlineData.getItem('latestCompetitors').then((latestComp: any) => {
      if (latestComp && latestComp?.length > 0) {
        const poppedComps: any[] = [];
        latestComp.forEach((compA: any) => {
          const pickedIndex = savedComps.findIndex(
            (compB: any) => compA.competitorName === compB.competitorName,
          );
          if (pickedIndex > -1) {
            poppedComps.push(savedComps[pickedIndex]);
            savedComps.splice(pickedIndex, 1);
          }
        });
        const newCompList = [...poppedComps, ...savedComps];
        setCompetitorsList(newCompList);
      } else {
        setCompetitorsList(savedComps);
      }
    });
  };

  useEffect(() => {
    dispatch(setLoader(true));
    offlineData.getItem('selectedProject').then((data) => {
      setSelectedProject(data);
    });
    setIsEdit(location?.state?.isEdit || false);
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      !isNullEmptyOrUndefined(data) && setSelectedRU(data);
      !isNullEmptyOrUndefined(data?.ruCode) && fetchCurrency(data?.ruCode);
    });
    !isIos() && offlineData.getItem('competitorsList').then((data: any) => {
      sortLatestCompetitors(data);
    });
    offlineData.getItem('comparisonList').then((data: any) => {
      setComparisonList(data);
    });
    // fetching master metric list
    offlineData.getItem('metricList').then((data: any) => {
      const metricListData = data?.filter((item: any) => (item.value !== piecesUnit.key));
      // appending pieces option to dropdown
      metricListData?.unshift(piecesUnit);
      setMasterUnitList(metricListData);
    });
    const isInstantComp = location.state?.isInstant;
    if (!isNullEmptyOrUndefined(isInstantComp)) {
      setIsInstant(location.state?.isInstant);
    } else {
      navigate(appRoutes.dashboard);
    }
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 300);
  }, []);

  useEffect(() => {
    if (isIos()) {
      offlineData.getItem('selectedRetailUnit').then((data: any) => {
        fetchCurrency(data?.ruCode || selectedRU?.ruCode);
      });
      offlineData.getItem('competitorsList').then((data: any) => {
        const iosCompList = data.filter((val:any) => val.ruCode === selectedRU?.ruCode);
        sortLatestCompetitors(iosCompList);
      });
    }
  }, [selectedRU]);

  const getDefaultUnit = () => {
    const defaultUnit: any[] = [];
    defaultUnit.push(piecesUnit);
    return defaultUnit;
  };

  const setIkeaItemData = (itemDetails:any, setCompMet: boolean) => {
    let ikeaItemUnit = '';
    let ikeaItemQuantityVal = 0;
    if (!isNullEmptyOrUndefined(itemDetails?.priceUnitDetail)) {
      offlineData.getItem('metricUnitOfRu').then((selectedMetric) => {
        if (!isNullEmptyOrUndefined(selectedMetric)) {
          if (selectedMetric === 'MET') {
            ikeaItemUnit = itemDetails?.priceUnitDetail?.priceUnitTextMetric || piecesUnit.key;
            ikeaItemQuantityVal = itemDetails?.priceUnitDetail?.priceUnitFactorMetric || itemDetails?.packageQuantity;
          } else {
            ikeaItemUnit = itemDetails?.priceUnitDetail?.priceUnitTextImperial || piecesUnit.key;
            ikeaItemQuantityVal = itemDetails?.priceUnitDetail?.priceUnitFactorImperial || itemDetails?.packageQuantity;
          }
        } else {
          ikeaItemUnit = piecesUnit.key;
          ikeaItemQuantityVal = itemDetails?.packageQuantity;
        }
        setIkeaItemMetric(ikeaItemUnit);
        setCompMet === true && setcompItemMetric(ikeaItemUnit);
        setIkeaItemQuantity(ikeaItemQuantityVal);
      });
    } else {
      ikeaItemUnit = piecesUnit.key;
      ikeaItemQuantityVal = itemDetails?.packageQuantity;
      setIkeaItemMetric(ikeaItemUnit);
      setCompMet === true && setcompItemMetric(ikeaItemUnit);
      setIkeaItemQuantity(ikeaItemQuantityVal);
    }
  };

  useEffect(() => {
    if (!isNullEmptyOrUndefined(selectedItem)) {
      const itemDetails = selectedItem?.itemInfos;
      const isFoodHfb = itemDetails?.isFoodItem;
      const isPriceUnit = itemDetails?.isPriceUnit;
      setIkeaItemData(itemDetails, true);
      setIsFoodhfb(isFoodHfb);
      setIsPriceunit(isPriceUnit);
      setItemPaNo(selectedItem?.paNo);
      setIkeaItemPrice(itemDetails?.priceInfo?.priceInclTax);
      setIkeaPriceUnitType(itemDetails?.priceUnitDetail?.priceUnitTypeName);
      setIkeaPriceUnitTypeNo(itemDetails?.priceUnitDetail?.priceUnitTypeNo);
      setItemNo(selectedItem.itemNo);
      if (isFoodHfb === true && isPriceUnit === true) {
        // get master unit list from local storage
        const masterList = JSON.parse(localStorage.getItem('metricList') || '');
        // fetching valid units for the food items
        const foodHfbItemUnits: any = itemDetails?.validUnits;
        // filtering the valid units for dropdown
        const updatedUnits: any[] = [];
        foodHfbItemUnits.forEach((foodUnit: any) => {
          updatedUnits.push(...masterList.filter((masterUnit: any) => masterUnit?.key === foodUnit));
        });
        // appending pieces option to dropdown
        updatedUnits.unshift(piecesUnit);
        setFoodHfbUnitList(updatedUnits);
      } else {
        // set the default unit for non food hfb items/food hfb items with no unit value from api
        setFoodHfbUnitList(getDefaultUnit());
        setcompItemMetric(piecesUnit.key);
      }
    } else if (location.state?.isInstant === true) {
      setIsFoodhfb(false);
      setIsPriceunit(false);
      setcompItemMetric(piecesUnit.key);
    }
  }, [selectedItem]);

  const onQuantityChange = (val: any) => {
    if (isPieces() && !val.toString().includes('.')) {
      setQuantityError(false);
    }
    setItemQuantity(val);
  };

  const onUnitSelect = (val: any, option: any) => {
    if (itemQuantity?.toString().length !== 0 && !isPieces() && val === piecesUnit.key && itemQuantity?.toString().includes('.')) {
      setQuantityError(true);
      setItemQuantity(undefined);
    } else {
      setQuantityError(false);
    }
    if (option.key === piecesUnit.key) {
      setIkeaItemQuantity(isEdit === true ? comparisonData?.ItemDetails?.packageQuantity : selectedItem?.itemInfos?.packageQuantity);
      setIkeaItemMetric(piecesUnit.key);
    } else {
      setIkeaItemData(isEdit === true ? comparisonData?.ItemDetails : selectedItem?.itemInfos, false);
    }
    setcompItemMetric(option?.key);
  };

  const setCompetitor = (value:string|number, name:string|number) => {
    setSelectedCompetitorId(value);
    setSelectedCompetitor(name);
  };

  const setRadioVal = (e: any, type:string) => {
    switch (type) {
      case 'quality':
        setItemQuality(e.target.value);
        break;
      case 'functionality':
        setItemFunctionality(e.target.value);
        break;
      default:
        break;
    }
  };

  const renderInstCompContent = [
    {
      step: 0,
      title: 'Retail Units',
      content: (
        <RuSection
          setSelectedRU={setSelectedRU}
          selectedRu={selectedRU}
          isEdit={isEdit}
        />
      ),
    },
    {
      step: 1,
      title: 'Competitor',
      content: (
        <CompetitorSection
          competitorsList={competitorList}
          setCompetitor={setCompetitor}
          selectedCompetitor={selectedCompetitor}
          compIndexFrmOutbox={compIndexFrmOutbox}
          onNoCompChecked={(isChecked) => setIsNoComp(isChecked)}
          setDisableNoComp={(isDisabled: boolean) => setDisableNoComp(isDisabled)}
          disableNoComp={disableNoComp}
          noCompDeletePc={noCompDeletePc}
          selectedItemDetails={selectedItem}
          onDeleteNoComp={() => setOpenDeletePrompt(true)}
          navSrc={navSrc}
          isEdit={isEdit}
          isInstant={isInstant}
          isNocomp={isNocomp}
          fromSummary={fromSummary}
        />
      ),
    },
    {
      step: 2,
      title: 'Phtoto Upload',
      content: (
        <PhotoUpload
          fileList={fileList}
          setFileList={setFileList}
          maliciousFileError={maliciousFileError}
          setMaliciousFileError={setMaliciousFileError}
        />
      ),
    },
    {
      step: 3,
      title: 'Number of pieces',
      content: (
        <NumberOfPieces
          itemQuantity={itemQuantity}
          setItemQuantity={setItemQuantity}
          selectedUnit={compItemMetric}
          onUnitSelect={onUnitSelect}
          onQuantityChange={onQuantityChange}
          quantityError={quantityError}
          setQuantityError={setQuantityError}
          unitList={isInstant ? masterUnitList : foodHfbUnitList}
          isFoodHfb={isFoodhfb}
          isPriceUnit={isPriceunit}
          isInstant={isInstant}
          piecesUnit={piecesUnit}
          ikeaItemMetric={ikeaItemMetric}
          ikeaItemQuantity={ikeaItemQuantity}
        />
      ),
    },
    {
      step: 4,
      title: 'Competitor price',
      content: (
        <CompetitorPrice
          competitorPrice={competitorPrice}
          currency={currency}
          showTimeRestricted={showTimeRestricted}
          setCompetitorPrice={setCompetitorPrice}
          setShowTimeRestricted={setShowTimeRestricted}
          setRegularPrice={setRegularPrice}
          setUnknownRegularPrice={setUnknownRegularPrice}
          setStartDate={setStartDate}
          setUnknownStartDate={setUnknownStartDate}
          setEndDate={setEndDate}
          setUnknownEndDate={setUnknownEndDate}
        />
      ),
    },
    {
      step: 5,
      title: 'TRO Start date',
      content: (
        <TroStartDate
          startDate={startDate}
          endDate={endDate}
          unknownStartDate={unknownStartDate}
          setUnknownStartDate={setUnknownStartDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      ),
    },
    {
      step: 6,
      title: 'TRO End date',
      content: (
        <TroEndDate
          startDate={startDate}
          endDate={endDate}
          unknownEndDate={unknownEndDate}
          setUnknownEndDate={setUnknownEndDate}
          setEndDate={setEndDate}
        />
      ),
    },
    {
      step: 7,
      title: 'TRO Regular price',
      content: (
        <TroRegularPrice
          regularPrice={regularPrice}
          currency={currency}
          unknownRegularPrice={unknownRegularPrice}
          setRegularPrice={setRegularPrice}
          setUnknownRegularPrice={setUnknownRegularPrice}
        />
      ),
    },
    {
      step: 8,
      title: 'Item Quality',
      content: (
        <ItemQualityFunctionality
          title="quality"
          itemQualityFunctionality={itemQuality}
          setRadioVal={setRadioVal}
        />
      ),
    },
    {
      step: 9,
      title: 'Item Functionality',
      content: (
        <ItemQualityFunctionality
          title="functionality"
          itemQualityFunctionality={itemFunctionality}
          setRadioVal={setRadioVal}
        />
      ),
    },
    {
      step: 10,
      title: 'Item Assembled',
      content: (
        <ItemAssembled
          title="assemble"
          itemAssembled={itemAssembled}
          setitemAssembled={setItemAssembled}
        />
      ),
    },
    {
      step: 11,
      title: 'Optional Fields',
      content: (
        <OptionalFields
          material={material}
          sustainability={sustainability}
          comment={comment}
          competitorItemName={competitorItemName}
          competitorItemUrl={competitorItemUrl}
          urlError={urlError}
          setMaterial={setMaterial}
          setSustainability={setSustainability}
          setComment={setComment}
          setCompetitorItemName={setCompetitorItemName}
          setCompetitorItemUrl={setCompetitorItemUrl}
          setUrlError={setUrlError}
          maliciousURLError={maliciousURLError}
          setMaliciousURLError={setMaliciousURLError}
        />
      ),
    },
    {
      step: 12,
      title: 'Summary',
      content: (
        <Summary
          isEdit={isEdit}
          onDeleteComparison={() => setOpenDeletePrompt(true)}
          setFromSummary={setFromSummary}
          selectedRu={selectedRU}
          selectedCompetitor={selectedCompetitor}
          fileList={fileList}
          competitorQuantity={itemQuantity}
          competitorPrice={competitorPrice}
          startDate={startDate}
          endDate={endDate}
          regularPrice={regularPrice}
          itemQuality={itemQuality}
          itemFunctionality={itemFunctionality}
          itemAssembled={itemAssembled}
          connectedItem={selectedItem}
          compItemMetric={compItemMetric}
          setCurrentStep={setCurrentStep}
          maliciousURLError={maliciousURLError}
          maliciousFileError={maliciousFileError}
        />
      ),
    },
  ];

  const navigateToNextStep = () => {
    if (fromSummary) {
      // From summary screen
      if (currentStep === 4 && !showTimeRestricted) {
        setCurrentStep(12);
      } else if ([4, 5, 6].includes(currentStep) && showTimeRestricted) {
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(12);
      }
    } else {
      if (currentStep === 4 && !showTimeRestricted) {
        setCurrentStep(currentStep + 4);
      } else {
        setCurrentStep(currentStep + 1);
      }
      setFromSummary(false);
    }
  };

  const navigateToPreviousStep = () => {
    if (fromSummary && currentStep !== 12) {
      setCurrentStep(12);
    } else if (currentStep === 8 && !showTimeRestricted) {
      setCurrentStep(currentStep - 4);
    } else if (currentStep === (isIos() ? 0 : 1)) {
      if (isEdit) {
        navigate(appRoutes.outbox);
      } else {
        removeSelectedRuForIos();
        navigateTo();
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
    setFromSummary(false);
  };

  const itemCriteria = (field: string | undefined) => {
    switch (field) {
      case 'better':
        return '10';
      case 'equal':
        return '20';
      case 'worse':
        return '30';
      default:
        return null;
    }
  };

  const itemAssemblyYorN = (field: boolean | undefined) => {
    if (field !== undefined) {
      return field ? '80' : '100';
    }
    return null;
  };

  const infoCollectedOnlineYorN = (field: boolean | undefined) => {
    switch (field) {
      case true:
        return '1';
      case false:
        return '0';
      default:
        return null;
    }
  };

  const showToast = (noComp: any) => {
    const meassage = noComp === 1 ? 'No competition has been saved' : 'Comparison has been saved';
    dispatch(setToast({
      openToast: true,
      toastLabel: meassage,
    }));
  };

  const updateLastCompetitor = (competitor: any) => {
    offlineData.getItem('latestCompetitors').then((latestComp: any) => {
      if (latestComp && latestComp?.length > 0) {
        const updatedComps = [...latestComp];
        if (updatedComps.length >= 3) {
          updatedComps.pop();
          updatedComps.unshift(competitor);
        } else {
          updatedComps.unshift(competitor);
        }
        offlineData.setItem('latestCompetitors', updatedComps);
      } else {
        offlineData.setItem('latestCompetitors', [competitor]);
      }
    });
  };

  const saveComparisonOffline = (payload: any) => {
    const initialSaveComparison: any = [];
    if (!isNullEmptyOrUndefined(comparisonList)) {
      if (isEdit) {
        const comparisonIndexStr = localStorage.getItem('selectedComparison');
        if (comparisonIndexStr) {
          const comparisonIndex = parseInt(comparisonIndexStr, 10);
          comparisonList[comparisonIndex] = payload;
          offlineData.setItem('comparisonList', comparisonList);
          localStorage.setItem('outboxCount', comparisonList.length);
        }
      } else {
        comparisonList.push(payload);
        offlineData.setItem('comparisonList', comparisonList);
        localStorage.setItem('outboxCount', comparisonList.length);
      }
    } else {
      initialSaveComparison.push(payload);
      offlineData.setItem('comparisonList', initialSaveComparison);
      localStorage.setItem('outboxCount', initialSaveComparison.length);
    }
    showToast(payload?.NoCompetition);
    updateLastCompetitor({
      competitorId: payload?.CompetitorId,
      competitorName: payload?.CompetitorName,
    });
    navigateTo();
  };

  const saveImageOffline = (payload: object) => {
    saveComparisonOffline(fileList.length !== 0 ? { ...payload, ImageList: fileList } : payload);
  };

  const getValidUnits = () => {
    if (isFoodhfb && isPriceunit) {
      return foodHfbUnitList;
    }
    return getDefaultUnit();
  };

  const buildComparisonPayload = () => {
    const payload = {
      NoCompetition: 0,
      ProjectType: projectType(),
      ProjectId: isInstant ? null : selectedProject?.projectId,
      UserId: userData()?.userId,
      UnitCode: selectedRU?.ruCode,
      CompetitorId: Number(selectedCompetitorId) || null,
      CompetitorName: selectedCompetitor || null,
      ImageList: [],
      ItemNo: itemNo || null,
      // Added this below 5 keys for edit comparison value retaining purpose
      IsFoodhfb: isFoodhfb,
      IsPriceunit: isPriceunit,
      item: !isNullEmptyOrUndefined(itemForEdit) ? itemForEdit : selectedItem,
      ItemDetails: isEdit === true ? comparisonData?.ItemDetails : selectedItem?.itemInfos,
      selectedRu: selectedRU,
      IsPieces: isPieces(),
      ValidUnits: getValidUnits(),
      IkeaItemQuantity: ikeaItemQuantity,
      CompPriceUnitText: (isNullEmptyOrUndefined(compItemMetric) || isPieces()) ? null : compItemMetric,
      PaNo: itemPaNo || null,
      IsFoodHfb: isFoodhfb === true ? 1 : 0,
      PriceUnitTypeNo: ikeaPriceUnitTypeNo || null,
      PriceUnitType: ikeaPriceUnitType || null,
      IkeaPriceUnitText: !isPieces() ? ikeaItemMetric : null,
      IkeaPriceUnitValue: !isPieces() ? ikeaItemQuantity : null,
      CompPriceUnitValue: !isPieces() ? Number(itemQuantity) : null,
      IsMobile: 1,
      CriteriaOptionList: [
        { // No of pieces or units
          CriteriaId: '20',
          CriteriaValue: isPieces() ? itemQuantity?.toString() : null,
        },
        { // Competitor price
          CriteriaId: '30',
          CriteriaValue: competitorPrice?.toString() || null,
        },
        { // Time Restricted Offer
          CriteriaId: '210',
          CriteriaValue: showTimeRestricted.toString(),
        },
        { // TRO - Regular price
          CriteriaId: '60',
          CriteriaValue: regularPrice || unknownRegularPrice,
        },
        { // TRO - Start date
          CriteriaId: '40',
          CriteriaValue: (startDate && !isNullEmptyOrUndefined(startDate)) ? startDate.format('YYYY-MM-DD') : unknownStartDate,
        },
        { // TRO - End date
          CriteriaId: '50',
          CriteriaValue: (endDate && !isNullEmptyOrUndefined(endDate)) ? endDate.format('YYYY-MM-DD') : unknownEndDate,
        },
        { // Competitor item quality
          CriteriaId: '70',
          CriteriaValue: itemCriteria(itemQuality),
        },
        { // Competitor item functionality
          CriteriaId: '80',
          CriteriaValue: itemCriteria(itemFunctionality),
        },
        { // Competitor item assembled before use
          CriteriaId: '90',
          CriteriaValue: itemAssemblyYorN(itemAssembled),
        },
        { // Optional - Comment
          CriteriaId: '170',
          CriteriaValue: convertToBase64(comment) || null,
        },
        { // Optional - Sustainability
          CriteriaId: '180',
          CriteriaValue: convertToBase64(sustainability) || null,
        },
        { // Optional - Material
          CriteriaId: '190',
          CriteriaValue: convertToBase64(material) || null,
        },
        { // Optional - Competitor item name
          CriteriaId: '200',
          CriteriaValue: competitorItemName || null,
        },
        { // Optional - Competitor item URL (Internet link)
          CriteriaId: '220',
          CriteriaValue: competitorItemUrl || null,
        },
        { // Item price from PIA
          CriteriaId: '320',
          CriteriaValue: ikeaItemPrice?.toString() || null,
        },
      ],
    };
    // Differentiating the payload project based IC or normal IC
    if (selectedItem) {
      fileList.length !== 0 ? saveImageOffline({
        ...payload,
        IsInstantCompare: 0,
      }) : saveComparisonOffline({
        ...payload,
        IsInstantCompare: 0,
      });
    } else {
      fileList.length !== 0 ? saveImageOffline({
        ...payload,
        IsInstantCompare: isInstant ? 1 : 0,
        ItemType: null,
      })
        : saveComparisonOffline({
          ...payload,
          IsInstantCompare: isInstant ? 1 : 0,
          ItemType: null,
        });
    }
  };

  const onSkip = () => {
    navigateToNextStep();
  };

  const validRuSelected = () => {
    if (currentStep === 0 && isIos()) {
      return (!isNullEmptyOrUndefined(selectedRU));
    }
    return true;
  };

  const validCompetitor = () => {
    if (currentStep === 1) {
      return !isNullEmptyOrUndefined(selectedCompetitor)
      || !isNullEmptyOrUndefined(selectedCompetitorId);
    }
    return true;
  };

  const validNumberOfPieces = () => {
    if (currentStep === 3) {
      if (itemQuantity && !isNullEmptyUndefinedOrZero(itemQuantity)
      && !checkZeroRegex.test(itemQuantity.toString())) {
        return true;
      }
      return false;
    }
    return true;
  };

  const validCompetitorPrice = () => {
    if (currentStep === 4) {
      if (competitorPrice && !isNullEmptyUndefinedOrZero(competitorPrice)
      && competitorPrice !== '.'
      && !checkZeroRegex.test(competitorPrice)) {
        return true;
      }
      return false;
    }
    return true;
  };

  const validTroStartDate = () => {
    if (currentStep === 5 && showTimeRestricted) {
      return (!isNullEmptyOrUndefined(startDate) || unknownStartDate);
    }
    return true;
  };

  const validTroEndDate = () => {
    if (currentStep === 6 && showTimeRestricted) {
      return (!isNullEmptyOrUndefined(endDate) || unknownEndDate);
    }
    return true;
  };

  const validTroRegularPrice = () => {
    if (currentStep === 7 && showTimeRestricted) {
      return (!isNullEmptyOrUndefined(regularPrice) || unknownRegularPrice);
    }
    return true;
  };

  const validItemQuality = () => {
    if (currentStep === 8) {
      return (!isNullEmptyOrUndefined(itemQuality));
    }
    return true;
  };

  const validItemFunctionality = () => {
    if (currentStep === 9) {
      return (!isNullEmptyOrUndefined(itemFunctionality));
    }
    return true;
  };

  const validItemAssembled = () => {
    if (currentStep === 10) {
      return (!isNullEmptyOrUndefined(itemAssembled));
    }
    return true;
  };

  const validUrl = () => {
    if (competitorItemUrl) {
      return !isNullEmptyOrUndefined(competitorItemUrl) && !urlError;
    }
    return true;
  };

  const disableNextButton = () => {
    if (validRuSelected()
    && validCompetitor()
    && validNumberOfPieces()
    && validCompetitorPrice()
    && validTroStartDate()
    && validTroEndDate()
    && validTroRegularPrice()
    && validItemQuality()
    && validItemFunctionality()
    && validItemAssembled()
    && validUrl()
    && ComparisonTextArea.material.textErr === false
    && ComparisonTextArea.sustainability.textErr === false
    && ComparisonTextArea.comment.textErr === false) {
      return false;
    }
    return true;
  };

  const hasNoOptionalEntry = () => isNullEmptyOrUndefined(material)
    && isNullEmptyOrUndefined(sustainability)
    && isNullEmptyOrUndefined(comment)
    && isNullEmptyOrUndefined(competitorItemName)
    && isNullEmptyOrUndefined(competitorItemUrl);

  const ifSkipEnabled = () => (currentStep === 11 && hasNoOptionalEntry())
    || (currentStep === 2 && isNullEmptyOrUndefined(fileList));

  const checkCompPriceValid = () => {
    let diffPercentage: any;
    if (competitorPrice && itemQuantity && ikeaItemQuantity && !isNullEmptyOrUndefined(ikeaItemPrice)) {
      let competitorPerPiece = 0;
      let ikeaPerPiece = 0;
      if (isFoodhfb && (ikeaItemMetric !== compItemMetric)) {
        competitorPerPiece = (Number(competitorPrice) / Number(convertUnit(compItemMetric, itemQuantity)));
        ikeaPerPiece = (Number(ikeaItemPrice) / Number(convertUnit(ikeaItemMetric, ikeaItemQuantity)));
      } else {
        competitorPerPiece = (Number(competitorPrice) / Number(itemQuantity));
        ikeaPerPiece = (Number(ikeaItemPrice) / Number(ikeaItemQuantity));
      }
      if (!isNullEmptyUndefinedOrZero(ikeaPerPiece)) {
        diffPercentage = (((competitorPerPiece - ikeaPerPiece) / ikeaPerPiece) * 100);
      } else {
        diffPercentage = null;
      }
      // checking the percentage diff with configured percentage
      if (isPieces() === true && (itemQuantity > compNoOfPieces)) {
        setOpenPricePopupSave(true);
      } else if (diffPercentage > compPriceDiff) {
        setOpenPricePopupSave(true);
      } else if (diffPercentage < -compPriceDiff) {
        setOpenPricePopupSave(true);
      } else {
        setOpenPricePopupSave(false);
        buildComparisonPayload();
      }
    } else {
      buildComparisonPayload();
    }
  };

  const checkSavePrerequisits = () => {
    if (isInstant && dontShowConnectedItemWarning !== 'true') {
      setOpenItemConnectionWarningModal(true);
    } else if (!isInstant) {
      checkCompPriceValid();
    } else {
      setOpenPricePopupSave(false);
      buildComparisonPayload();
    }
  };

  const saveNoCompetition = () => {
    const savePayload = {
      ProjectId: selectedProject?.projectId,
      ItemNo: itemNo || null,
      NoCompetition: 1,
      ProjectType: projectType(),
      UserId: userData()?.userId,
      UpdateUserId: userData()?.userId,
      UnitCode: selectedRU?.ruCode,
      item: !isNullEmptyOrUndefined(itemForEdit) ? itemForEdit : selectedItem,
      CompetitorId: Number(selectedCompetitorId) || null,
      CompetitorName: selectedCompetitor || null,
      PaNo: itemPaNo || null,
      IsMobile: 1,
      IsInstantCompare: 0,
    };
    saveComparisonOffline(savePayload);
  };

  const saveButtonText = () => {
    let text = isEdit ? updateComparisonText : saveComparisonText;
    if (isNocomp === true) { text = 'Save'; }
    return text;
  };

  const disableSave = () => {
    if (isNocomp === true) {
      return isNullEmptyOrUndefined(selectedCompetitor) || disableNoComp === true || navSrc === 'outbox';
    }
    return false;
  };

  const renderActionButton = () => {
    if (currentStep !== 12 && isNocomp === false) {
      return (
        ifSkipEnabled() ? (
          <Button
            className={styles.mobileNextButton}
            text="Skip"
            type="secondary"
            onClick={onSkip}
            small
          />
        ) : (
          <Button
            className={styles.mobileNextButton}
            text="Next"
            type="emphasised"
            onClick={navigateToNextStep}
            disabled={disableNextButton()}
            small
          />
        )
      );
    } return (
      <Button
        className={styles.btIcSaveComparison}
        text={saveButtonText()}
        type="emphasised"
        onClick={isNocomp === true ? saveNoCompetition : checkSavePrerequisits}
        disabled={disableSave()}
        small
      />
    );
  };

  const handleCloseModalButtons = (type?:number) => {
    if (type === 2) {
      removeSelectedRuForIos();
      navigateTo();
    }
    setOpenCloseModal(false);
  };

  const handlePriceModalButtons = (type?:number) => {
    type === 2 && buildComparisonPayload();
    setOpenPricePopupSave(false);
  };

  const handleDeletePromptAction = (type?:number) => {
    if (type === 2) {
      const comparisonIndexStr = localStorage.getItem('selectedComparison');
      let comparison;
      if (comparisonIndexStr) {
        const comparisonIndex = parseInt(comparisonIndexStr, 10);
        comparison = comparisonList[comparisonIndex];
      }
      if (comparisonIndexStr) {
        const comparisonIndex = parseInt(comparisonIndexStr, 10);
        const updatedComparisonList = comparisonList.filter(
          (comp: any, index: any) => comparisonIndex !== index,
        );
        offlineData.setItem('comparisonList', updatedComparisonList);
        localStorage.setItem('outboxCount', updatedComparisonList?.length.toString() || '0');
        setComparisonList(updatedComparisonList);
        dispatch(setToast({
          openToast: true,
          toastLabel: isNocomp === true ? deleteNoCompMsg : deleteCompMsg,
        }));
        const deleteLoc = localStorage.getItem('deleteLoc');
        if (navSrc === 'outbox' && (isNoCompetition === 1 || deleteLoc === 'summary')) {
          navigate(appRoutes.outbox);
          setNoCompDeletePc(false);
        } else {
          setNoCompDeletePc(true);
          setTimeout(() => {
            !isNullEmptyOrUndefined(compIndexFrmOutbox) && localStorage.setItem('selectedComparison', compIndexFrmOutbox.toString());
          }, 1000);
        }
      }
    }
    setOpenDeletePrompt(false);
  };

  return (
    <div>
      <TopNavigationBar
        isInstantComparison={isInstant}
        onPreviousClick={navigateToPreviousStep}
        handleCloseModal={handleCloseModal}
        selectedItem={isEdit === true ? itemForEdit : selectedItem}
      />
      <Steps
        current={currentStep}
      />
      {!isLoading ? renderInstCompContent[currentStep]?.content : null}
      {renderActionButton()}
      <CloseModal
        modelOpen={openCloseModal}
        closeModal={handleCloseModalButtons}
        saveValid
        closeText={useTranslate('common.no')}
        saveText={useTranslate('common.yes')}
        title={useTranslate('common.confirmation.title')}
        subText={useTranslate('inst.comp.closeModal_subtext')}
      />
      <CloseModal
        modelOpen={openPricePopupSave}
        closeModal={handlePriceModalButtons}
        saveValid
        closeText={useTranslate('inst.comp.priceModal_closetext')}
        saveText={useTranslate('inst.comp.priceModal_savetext')}
        title={useTranslate('common.confirmation.title')}
        subText={`${useTranslate('inst.comp.priceModal_subtext1')}${compPriceDiff}${useTranslate('inst.comp.priceModal_subtext2')}`}
      />
      <CloseModal
        modelOpen={openDeletePrompt}
        closeModal={handleDeletePromptAction}
        saveValid
        closeText={useTranslate('common.no')}
        saveText={useTranslate('common.yes')}
        title={useTranslate('common.confirmation.title')}
        subText={isNocomp === true ? delNocompText : delCompText}
      />
      <ItemConnectionWarning
        isVisible={openItemConnectionWarningModal}
        onClose={() => setOpenItemConnectionWarningModal(false)}
        onSave={buildComparisonPayload}
      />
    </div>
  );
};

export default InstantComparison;
