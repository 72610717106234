/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import Text from '@ingka/text';
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';
import { isNullEmptyOrUndefined } from '../../../../../common/services/commonService.js';
import { useTranslate } from '../../../../../common/services/translationService.js';

interface PillsToolbarProps {
  data: any;
  hfbData: any;
  ruSuData: any;
  suList: any;
  rocList: any;
  priceClass: any;
  strategicPricing: any;
  sellingPriceList: any;
  rspList: any;
  competitorList: any;
  setAllFromDate: any;
  setAllToDate: any;
  setStartDate: any;
  setEndDate: any;
  setItemQuality: any;
  setItemFunctionality: any;
  setPhoto: any;
  setCompetition: any;
  totalComparison: any;
  onRemoveFilter: (newPayload: any, newHfbData: any, newRuSuData: any) => void;
  onClearAll: () => void;
  setMyComparison: (isMyComparison: boolean) => void;
  onRemoveCompetitor: (newCompList: any[]) => void;
}

enum FilterType {
  hfb = 'HFB',
  pra = 'PRA',
  pa = 'PA',
  ru = 'RU',
  su = 'SU',
  roc = 'ROC',
  sp = 'Strategic pricing',
  pc = 'Price class',
  fromDate = 'From date',
  toDate = 'To date',
  itemQuality = 'Item quality',
  itemFunctionality = 'Item functionality',
  sellPrice = 'Selling price',
  rsp = 'RSP',
  competition = 'Competition',
  photos = 'Photos',
  myComp = 'My comparisons',
  competitor = 'Competitor'
}

const PillsToolbar = ({
  data,
  hfbData,
  ruSuData,
  suList,
  rocList,
  priceClass,
  strategicPricing,
  sellingPriceList,
  rspList,
  competitorList,
  setStartDate,
  setAllFromDate,
  setAllToDate,
  setEndDate,
  setItemQuality,
  setItemFunctionality,
  setPhoto,
  setCompetition,
  totalComparison,
  onRemoveFilter,
  onClearAll,
  setMyComparison,
  onRemoveCompetitor,
}: PillsToolbarProps) => {
  const noCompetition = useTranslate('sendProject.noCompetition');
  const withPhoto = useTranslate('common.with_photo');
  const withoutPhoto = useTranslate('common.without_photo');
  const removeItemFromArray = (arr:any[], value: any) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const criteriaValue = (field: string | undefined) => {
    switch (field) {
      case '10':
        return 'better';
      case '20':
        return 'equal';
      case '30':
        return 'worse';
      default:
        return null;
    }
  };

  const competitionVal = (field: number | null) => {
    switch (field) {
      case 2:
        return null;
      case 0:
        return { value: 0, key: 'onlyComp', label: 'Comparisons only' };
      case 1:
        return { value: 1, key: 'noComp', label: noCompetition };
      default:
        return undefined;
    }
  };

  const photoYorN = (field: string | null) => {
    if (field === '1') {
      return { value: '1', key: 'photo', label: withPhoto };
    }
    if (field === '0') {
      return { value: '0', key: 'noPhoto', label: withoutPhoto };
    }
    return undefined;
  };

  const removeCommon = (first: any, second: any) => {
    const combine = [...first, ...second];
    return combine.filter((el) => !(first.includes(el) && second.includes(el)));
  };

  const removeHfbItemFromArray = (payload: any, hfbDataList: any, filter: any) => {
    const hfbFilterData: any = [];
    if (!isNullEmptyOrUndefined(payload.PraNos) || !isNullEmptyOrUndefined(payload.PaNos)) {
      hfbFilterData.push(hfbDataList.filter((item: any) => filter.includes(item.slice(0, 2))));
      return removeCommon(hfbFilterData[0], hfbDataList);
    }
    return hfbDataList.filter((item: any) => payload.HfbNos.includes(item.slice(0, 2)));
  };

  const removePraItemFromArray = (payload: any, hfbDataList: any, filter: any) => {
    const praFilterData: any = [];
    if (!isNullEmptyOrUndefined(payload.HfbNos) || !isNullEmptyOrUndefined(payload.PaNos)) {
      praFilterData.push(hfbDataList.filter((item: any) => filter.includes(item.slice(0, 3))));
      return removeCommon(praFilterData[0], hfbDataList);
    }
    return hfbDataList.filter((item:any) => payload.PraNos.includes(item.slice(0, 3)));
  };

  const removeRuItemFromArray = (payload: any, suData: any, val: any, filter: any) => {
    const suFilterData: any = [];
    const selectedRUCodes: any = [];
    const removedFilterRUCodes: any = [];
    removedFilterRUCodes.push(filter);
    selectedRUCodes.push(
      isNullEmptyOrUndefined(payload.SuCodes) ? payload.RuCodes : removedFilterRUCodes,
    );
    selectedRUCodes[0].forEach((ruCode: any) => {
      suData.forEach((su: any) => {
        if (ruCode === su.value) {
          suFilterData.push(su.key);
        }
      });
    });
    return isNullEmptyOrUndefined(payload.SuCodes) ? suFilterData
      : removeCommon([...selectedRUCodes[0], ...suFilterData], val);
  };

  const removeFilter = (filterVal: any, type: FilterType) => {
    const tempPayload = { ...data };
    let tempHfbData = [...hfbData];
    let tempRuSuData = [...ruSuData];
    const suData = [...suList];
    switch (type) {
      case FilterType.hfb:
        tempPayload.HfbNos = removeItemFromArray(tempPayload?.HfbNos, filterVal);
        tempHfbData = removeHfbItemFromArray(tempPayload, tempHfbData, filterVal);
        break;
      case FilterType.pra:
        tempPayload.PraNos = removeItemFromArray(tempPayload?.PraNos, filterVal);
        tempHfbData = removePraItemFromArray(tempPayload, tempHfbData, filterVal);
        break;
      case FilterType.pa:
        tempPayload.PaNos = removeItemFromArray(tempPayload?.PaNos, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.ru:
        tempPayload.RuCodes = removeItemFromArray(tempPayload?.RuCodes, filterVal);
        tempRuSuData = removeRuItemFromArray(tempPayload, suData, tempRuSuData, filterVal);
        break;
      case FilterType.su:
        tempPayload.SuCodes = removeItemFromArray(tempPayload?.SuCodes, filterVal);
        tempRuSuData = removeItemFromArray(tempRuSuData, filterVal);
        break;
      case FilterType.roc:
        tempPayload.RocNos = removeItemFromArray(tempPayload?.RocNos, filterVal);
        break;
      case FilterType.pc:
        tempPayload.PcNos = removeItemFromArray(tempPayload?.PcNos, filterVal);
        break;
      case FilterType.sp:
        tempPayload.SpNos = removeItemFromArray(tempPayload?.SpNos, filterVal);
        break;
      case FilterType.fromDate:
        tempPayload.FromDate = null;
        setStartDate(null);
        setAllFromDate(null);
        break;
      case FilterType.toDate:
        tempPayload.ToDate = null;
        setEndDate(null);
        setAllToDate(null);
        break;
      case FilterType.itemQuality:
        tempPayload.ItemQuality = null;
        setItemQuality(null);
        break;
      case FilterType.itemFunctionality:
        tempPayload.ItemFunctionality = null;
        setItemFunctionality(null);
        break;
      case FilterType.sellPrice:
        tempPayload.SellingPrice = removeItemFromArray(tempPayload?.SellingPrice, filterVal);
        break;
      case FilterType.rsp:
        tempPayload.RecSalesPrice = removeItemFromArray(tempPayload?.RecSalesPrice, filterVal);
        break;
      case FilterType.competition:
        tempPayload.NoCompetition = 2;
        setCompetition('2');
        break;
      case FilterType.photos:
        tempPayload.Photos = null;
        setPhoto(null);
        break;
      case FilterType.myComp:
        tempPayload.UserId = null;
        setMyComparison(false);
        break;
      case FilterType.competitor:
        tempPayload.CompetitorIds = removeItemFromArray(tempPayload?.CompetitorIds, filterVal);
        onRemoveCompetitor(tempPayload.CompetitorIds);
        break;
      default: break;
    }
    onRemoveFilter(tempPayload, tempHfbData, tempRuSuData);
  };

  const buildLabel = (type: FilterType, filter: any) => {
    if ([FilterType.hfb,
      FilterType.pra,
      FilterType.pa,
      FilterType.ru,
      FilterType.su,
      FilterType.myComp].includes(type)) {
      return filter?.label || filter;
    }
    return `${type}: ${filter?.label || filter?.text || filter}`;
  };

  const renderPills = (filterList: any[], type: FilterType) => {
    let valueArray = [...filterList];
    switch (type) {
      case FilterType.roc:
        valueArray = rocList.filter((roc: any) => filterList.includes(roc.value));
        break;
      case FilterType.pc:
        valueArray = priceClass.filter((pc: any) => filterList.includes(pc.value));
        break;
      case FilterType.sp:
        valueArray = strategicPricing.filter((sp: any) => filterList.includes(sp.value));
        break;
      case FilterType.sellPrice:
        valueArray = sellingPriceList.filter((sp: any) => filterList.includes(sp.value));
        break;
      case FilterType.rsp:
        valueArray = rspList.filter((rsp: any) => filterList.includes(rsp.value));
        break;
      case FilterType.competitor:
        valueArray = competitorList.filter((comp: any) => filterList.includes(comp.id));
        break;
      case FilterType.competition:
        valueArray = [competitionVal(filterList[0])];
        break;
      case FilterType.photos:
        valueArray = [photoYorN(filterList[0])];
        break;
      default: break;
    }

    return valueArray?.map((filter: any) => (
      !isNullEmptyOrUndefined(filter) && (
      <Pill
        key={`${type}${filter?.label || filter?.text || filter}`}
        className={`${styles.pill} ${styles.pillBorder} noselect`}
        label={buildLabel(type, filter)}
        small
        ssrIcon={IcCross}
        onClick={() => {
          let val = filter;
          if ([FilterType.roc,
            FilterType.pc,
            FilterType.sp,
            FilterType.sellPrice,
            FilterType.rsp].includes(type)) {
            val = filter?.value;
          } else if (type === FilterType.competitor) {
            val = filter?.id;
          }
          removeFilter(val, type);
        }}
      />
      )
    ));
  };

  return (
    <div className={styles.pillsContainer}>
      {data?.HfbNos?.length > 0 ? (
        renderPills(data?.HfbNos, FilterType.hfb)
      ) : null}
      {data?.PraNos?.length > 0 ? (
        renderPills(data?.PraNos, FilterType.pra)
      ) : null}
      {data?.PaNos?.length > 0 ? (
        renderPills(data?.PaNos, FilterType.pa)
      ) : null}
      {!isNullEmptyOrUndefined(data?.FromDate) ? (
        renderPills([data?.FromDate], FilterType.fromDate)
      ) : null}
      {!isNullEmptyOrUndefined(data?.ToDate) ? (
        renderPills([data?.ToDate], FilterType.toDate)
      ) : null}
      {data?.RuCodes?.length > 0 ? (
        renderPills(data?.RuCodes, FilterType.ru)
      ) : null}
      {data?.SuCodes?.length > 0 ? (
        renderPills(data?.SuCodes, FilterType.su)
      ) : null}
      {!isNullEmptyOrUndefined(data?.UserId) ? (
        renderPills([FilterType.myComp], FilterType.myComp)
      ) : null}
      {data?.CompetitorIds?.length > 0 ? (
        renderPills(data?.CompetitorIds, FilterType.competitor)
      ) : null}
      {!isNullEmptyOrUndefined(data?.ItemFunctionality) ? (
        renderPills([criteriaValue(data?.ItemFunctionality)], FilterType.itemFunctionality)
      ) : null}
      {!isNullEmptyOrUndefined(data?.ItemQuality) ? (
        renderPills([criteriaValue(data?.ItemQuality)], FilterType.itemQuality)
      ) : null}
      {data?.RocNos?.length > 0 ? (
        renderPills(data?.RocNos, FilterType.roc)
      ) : null}
      {data?.PcNos?.length > 0 ? (
        renderPills(data?.PcNos, FilterType.pc)
      ) : null}
      {data?.SpNos?.length > 0 ? (
        renderPills(data?.SpNos, FilterType.sp)
      ) : null}
      {!isNullEmptyOrUndefined(data?.Photos) ? (
        renderPills([data?.Photos], FilterType.photos)
      ) : null}
      {data?.SellingPrice?.length > 0 ? (
        renderPills(data?.SellingPrice, FilterType.sellPrice)
      ) : null}
      {(!isNullEmptyOrUndefined(data?.NoCompetition) && !Number.isNaN(data?.NoCompetition)) ? (
        renderPills([data?.NoCompetition], FilterType.competition)
      ) : null}
      {data?.RecSalesPrice?.length > 0 ? (
        renderPills(data?.RecSalesPrice, FilterType.rsp)
      ) : null}
      <Pill
        className={`${styles.pill} noselect`}
        label={useTranslate('find.comp.clearAll')}
        small
        onClick={onClearAll}
      />
      {totalComparison > 0 ? (
        <Text className={styles.fcTotalCount}>
          {`(${totalComparison}  ${totalComparison > 1 ? 'Comparisons' : 'Comparison'})`}
        </Text>
      ) : null}
    </div>
  );
};

export default PillsToolbar;
