/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Text from '@ingka/text';
import SSRIcon from '@ingka/ssr-icon';
import IcClose from '@ingka/ssr-icon/paths/cross';
import { Tree } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import './HfbSelection.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import { appRoutes } from '../../../../common/constants/constant';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import useCallApi from '../../../../common/services/apiService.js';
import { useAppDispatch } from '../../../../store/hooks';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import { fetchCompetitorData, fetchLookupEnums } from '../../../../common/services/commonApi.js';

const HfbSelection = () => {
  const navigate = useNavigate();
  const [selectedHfb, setSelectedHfb] = useState([]);
  const [hfbData, setHfbData] = useState([]);
  const [disableHfb, setDisableHfb] = useState(true);
  const location: any = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const { get, post } = useCallApi();
  const dispatch = useAppDispatch();
  const hfbError = useTranslate('login.hfb.error');

  const onHfbSave = () => {
    offlineData.setItem('selectedHfbs', selectedHfb);
    navigate(appRoutes.dashboard, { state: isEdit });
  };

  const fetchHFBData = () => {
    dispatch(setLoader(true));
    get('business-structure', (hfbList:[]) => {
      offlineData.setItem('hfbData', hfbList);
      setHfbData(hfbList);
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: hfbError }));
    });
  };

  const getHFBData = () => {
    offlineData.getItem('hfbData')
      .then((data: any) => {
        if (isNullEmptyOrUndefined(data) && navigator.onLine) {
          fetchHFBData();
        } else { setHfbData(data); }
      });
  };

  const getCurrencyList = () => {
    offlineData.getItem('currencyList')
      .then((data: any) => {
        if (isNullEmptyOrUndefined(data) && navigator.onLine) { fetchLookupEnums(get); }
      });
  };

  const getMetricData = () => {
    offlineData.getItem('metricUnitOfRu')
      .then((data: any) => {
        if (isNullEmptyOrUndefined(data) && navigator.onLine) { fetchLookupEnums(get); }
      });
  };

  const getCompetitorData = async () => {
    offlineData.getItem('competitorsList')
      .then(async (data: any) => {
        if (isNullEmptyOrUndefined(data) && navigator.onLine) {
          fetchCompetitorData(post);
        }
      });
  };

  const setSellingUnits = (data: any) => {
    offlineData.setItem('userSellingUnits', data);
  };

  const getSellingUnitsData = () => {
    offlineData.getItem('userSellingUnits')
      .then((data: any) => {
        if (isNullEmptyOrUndefined(data) && navigator.onLine) {
          get('selling-units', setSellingUnits);
        }
      });
  };

  useEffect(() => {
    const isFromHamburger = location?.state?.isEdit;
    setIsEdit(isFromHamburger || false);
    dispatch(setLoader(true));
    setTimeout(() => {
      getHFBData();
      getSellingUnitsData();
      if (navigator.onLine === true) {
        if (isFromHamburger) {
          getCompetitorData();
        } else {
          fetchCompetitorData(post);
        }
      }
      if (navigator.onLine === true) getCurrencyList();
      if (navigator.onLine === true) getMetricData();
      dispatch(setLoader(false));
    }, 1000);
    localStorage.setItem('loggedOut', 'false');
  }, []);

  useEffect(() => {
    offlineData.getItem('selectedHfbs').then((data: any) => {
      setDisableHfb(isNullEmptyOrUndefined(data) || data?.length === 0);
      setSelectedHfb(data || []);
    });
  }, [hfbData]);

  const onCheckboxChange = (checkedKeysValue: any) => {
    setDisableHfb(checkedKeysValue.length === 0);
    setSelectedHfb(checkedKeysValue);
  };

  return (
    <>
      {isEdit ? (
        <div className="hfbHeaderWrapper">
          <Button
            className="hfbMobileCloseButton"
            iconOnly
            small
            type="tertiary"
            onClick={() => navigate(appRoutes.dashboard)}
          >
            <SSRIcon paths={IcClose} />
          </Button>
        </div>
      ) : null}
      <div className={isEdit ? 'changeHfbContentOuterWrapper' : 'mobileContentOuterWrapper'}>
        <Text className={isEdit ? 'changeHfbTitle' : 'mobileTitle'}>
          {useTranslate('mobile.initial.hfbSelectionTitle')}
        </Text>
        <div className="mobileContentWrapper">
          <Tree
            checkable
            checkedKeys={selectedHfb}
            treeData={hfbData}
            fieldNames={{
              title: 'codeName',
              key: 'id',
            }}
            onCheck={onCheckboxChange}
            virtual={false}
          />
        </div>
        <Button
          className="mobileCountinueButton"
          text={useTranslate('common.text.continue')}
          type="emphasised"
          onClick={onHfbSave}
          disabled={disableHfb}
        />
      </div>
    </>
  );
};

export default HfbSelection;
