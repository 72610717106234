/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import React, { useEffect, useState } from 'react';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import IcPlaceholder from '@ingka/ssr-icon/paths/image';
import Button from '@ingka/button';
import type { RcFile } from 'antd/es/upload/interface';
import { useLocation, useNavigate } from 'react-router-dom';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import AppBarMobile from '../../../common/components/appBarMobile/AppBarMobile';
import { offlineData } from '../../../common/configs/offlineData';
import { useTranslate } from '../../../common/services/translationService.js';
import styles from './OutboxComponent.module.css';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';
import { useAppDispatch } from '../../../store/hooks';
import useCallApi from '../../../common/services/apiService.js';
import { appRoutes } from '../../../common/constants/constant';
import {
  env, isIos, isNullEmptyOrUndefined,
  isNullEmptyUndefinedOrZero,
  isRu,
  isSu,
  userData,
} from '../../../common/services/commonService.js';

const OutboxComponent = () => {
  const [comparisonList, setComparisonList] = useState<any[]>([]);
  const [selectedRuCode, setSelectedRuCode] = useState<any>(null);
  const [uploadDisable, setUploadDisable] = useState<boolean>(false);
  const [highlightDays, setHighlightDays] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const [currency, setCurrency] = useState(null);
  const { post } = useCallApi();
  const navigate = useNavigate();
  const location = useLocation();
  const emptyText = useTranslate('outbox.empty');
  const successMsgFull = useTranslate('outbox.successMessage.complete');
  const successMsgPartial = useTranslate('outbox.successMessage.partial');
  const errMsgPartial = useTranslate('outbox.errMessage.partial');
  const maliciousUrlMsg = useTranslate('inst.comp.threatUrl');
  const maliciousFileMsg = useTranslate('inst.comp.threatFile');
  const noCompetition = useTranslate('sendProject.noCompetition');
  const redirectPath: any = location?.state;
  const savedComparisonIndices: number[] = [];
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  let logoutUploadCount = 1;

  const fetchComparisons = () => {
    offlineData.getItem('comparisonList').then((data: any) => {
      setComparisonList(data);
      if (([appRoutes.RUselect, appRoutes.HFBselect, 'signout'].includes(redirectPath)) && uploadDisable === false && logoutUploadCount === 1) {
        ++logoutUploadCount;
        handleUpload(0);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('storage', () => {
      const daysToHighlight = localStorage.getItem('highlightDays');
      setHighlightDays(daysToHighlight ? parseInt(daysToHighlight, 10) : null);
    });
  });

  useEffect(() => {
    if (currency) { fetchComparisons(); }
  }, [currency]);

  const fetchCurrency = (ruCode: string) => {
    offlineData.getItem('currencyList').then((currencyList: any) => {
      if (currencyList?.length > 0) {
        const currencyCode = currencyList.find((item: any) => item.key === ruCode);
        setCurrency(currencyCode.value);
      }
    });
  };

  useEffect(() => {
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      if (data?.ruCode) {
        fetchCurrency(data.ruCode);
        setSelectedRuCode(data.ruCode);
      }
    });
    fetchComparisons();
    const daysToHighlight = localStorage.getItem('highlightDays');
    setHighlightDays(daysToHighlight ? parseInt(daysToHighlight, 10) : null);
  }, []);

  const logOut = () => {
    localStorage.setItem('isSignedOut', 'true');
    logout({ returnTo: env().logoutUrl });
  };

  const removeCompletedComparisons = () => {
    const updatedComparisonList = comparisonList.filter(
      (comparison, index) => !savedComparisonIndices.includes(index),
    );
    offlineData.setItem('comparisonList', updatedComparisonList);
    localStorage.setItem('outboxCount', updatedComparisonList?.length.toString() || '0');
    setComparisonList(updatedComparisonList);
    isIos() && offlineData.removeItem('selectedRetailUnit');
    if (updatedComparisonList.length === 0) {
      dispatch(setToast({
        openToast: true,
        toastLabel: `${savedComparisonIndices.length} ${successMsgFull}`,
      }));
      if (redirectPath === 'signout') {
        logOut();
      } else {
        navigate(
          redirectPath || appRoutes.dashboard,
          { state: { isEdit: !!redirectPath } },
        );
      }
    } else {
      dispatch(setToast({
        openToast: true,
        toastLabel: checkUploadDisable(updatedComparisonList) === false
          ? successMsgPartial : errMsgPartial,
      }));
      if (redirectPath === 'signout') {
        logOut();
      }
    }
    savedComparisonIndices.length = 0;
    dispatch(setLoader(false));
  };

  const onSaveSuccess = (index: number) => {
    if (index === comparisonList.length - 1) {
      removeCompletedComparisons();
    } else {
      handleUpload(index + 1);
    }
  };

  const onSaveFailure = (index: number) => {
    if (index === comparisonList.length - 1) {
      dispatch(setLoader(false));
      removeCompletedComparisons();
    } else {
      handleUpload(index + 1);
    }
  };

  const saveComparison = (payload: object, index: number) => {
    dispatch(setLoader(true));
    post(
      'save-comparison',
      payload,
      (data: any) => {
        if (data && data.isSuccess) {
          savedComparisonIndices.push(index);
          onSaveSuccess(index);
        }
      },
      (err:AxiosError) => {
        if (err?.code === 'ERR_NETWORK') {
          comparisonList[index].urlError = true;
        }
        onSaveFailure(index);
      },
    );
  };

  const buildRuCode = () => {
    if (isSu()) {
      return localStorage.getItem('RuCodeForSu') || '';
    }
    return selectedRuCode || '';
  };

  const handleImageUpload = (payload: any, itemNameParam: any, index: number) => {
    dispatch(setLoader(true));
    const formData = new FormData();
    payload?.ImageList?.forEach((file: any) => {
      const lastIndex = file.name.lastIndexOf('.');
      const replaced = `IMAGE${file.name.substring(lastIndex)}`;
      formData.append('newImages', file.originFileObj as RcFile, replaced);
    });
    const itemName = itemNameParam?.replaceAll('/', '');
    const ItemNameTrimmed = itemName?.replace(/\s+/g, '');
    const compRuCode = isIos() ? payload?.UnitCode : buildRuCode();
    formData.append('itemName', ItemNameTrimmed || '');
    formData.append('competetorName', payload?.CompetitorName as any);
    formData.append('retailUnit', compRuCode);
    post(
      'upload-image',
      formData,
      (res:[]) => {
        const errorFiles = res.filter((file: any) => file?.status?.toString()?.toLowerCase() === 'fail');
        if (errorFiles?.length >= 1) {
          comparisonList[index].fileError = true;
          onSaveFailure(index);
        } else {
          const imgData = res.map((val: any) => ({ FileName: val.fileName }));
          saveComparison(imgData.length !== 0
            ? { ...payload, ImageList: imgData }
            : payload, index);
        }
      },
      () => {
        dispatch(setLoader(false));
        dispatch(setToast({ openToast: true, toastLabel: 'Failed to upload images' }));
      },
    );
  };

  const handleUpload = (index: number) => {
    if (!window.navigator.onLine) {
      dispatch(setToast({ openToast: true, toastLabel: 'application.internet.alert' }));
    } else if (isAuthenticated === false) {
      loginWithRedirect();
    } else if (!isNullEmptyOrUndefined(comparisonList) && index <= comparisonList.length) {
      const comparison: any = { ...comparisonList[index] };
      // hanlde TRO fields
      if (disableComparison(comparison) === false) {
        if (comparison.NoCompetition !== 1) {
          const troStartDate = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '40').CriteriaValue;
          const troEndDate = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '50').CriteriaValue;
          const troRegularPrice = comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '60').CriteriaValue;
          if (typeof troStartDate === 'boolean') {
            comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '40').CriteriaValue = null;
          }
          if (typeof troEndDate === 'boolean') {
            comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '50').CriteriaValue = null;
          }
          if (typeof troRegularPrice === 'boolean') {
            comparison.CriteriaOptionList.find((option: any) => option.CriteriaId === '60').CriteriaValue = null;
          }
        }

        // removing unwanted params to payload
        delete comparison.IsPriceunit;
        delete comparison.ValidUnits;
        delete comparison.IsFoodhfb;
        delete comparison.IkeaItemQuantity;
        delete comparison.ItemDetails;
        delete comparison.IsPieces;
        delete comparison.selectedRu;
        const itemName = comparison?.item?.itemName || null;
        comparison.item && delete comparison.item;

        // upload call
        if (comparison?.ImageList?.length !== 0
        && !isNullEmptyUndefinedOrZero(comparison?.ImageList?.length)) {
          handleImageUpload(comparison, itemName, index);
        } else {
          saveComparison(comparison, index);
        }
      } else {
        onSaveSuccess(index);
      }
    }
  };

  const onComparisonClick = (index: number, comparison:any) => {
    localStorage.setItem('selectedComparison', index.toString());
    navigate(appRoutes.IC, {
      state: {
        isEdit: true, compIndex: index, isNoCompetition: comparison?.NoCompetition, isInstant: false, from: 'outbox',
      },
    });
  };

  const dateDifference = (date: string) => {
    const eventdate = dayjs(date);
    const todaysdate = dayjs();
    return eventdate.diff(todaysdate, 'days');
  };

  const shouldHighlight = (comparison: any) => {
    if (!isNullEmptyOrUndefined(highlightDays)
    && comparison?.IsInstantCompare !== 1
    && highlightDays !== null
    && isIos() === false) {
      const date = comparison?.NoCompetition === 1
        ? comparison?.item?.itemInfos?.deadlineDate : comparison?.ItemDetails?.deadlineDate;
      return dateDifference(date) < highlightDays;
    }
    return false;
  };

  const checkUploadDisable = (compList?:any) => {
    let i = 0;
    const comparisons = !isNullEmptyOrUndefined(compList) ? compList : comparisonList || [];
    if (comparisons?.length !== 0) {
      comparisons.map((val: any, x :number) => {
        if (isSu()) {
          if ((val.ProjectType === 2)) {
            i = ++i;
          }
        }
        if (isIos()) {
          if ((val.ProjectType === 0) && val.UserId === userData().userId) {
            i = ++i;
          }
        }
        if (isRu()) {
          if ((val.ProjectType !== 0)) {
            i = ++i;
          }
        }
        if (x + 1 === comparisons?.length) {
          if (i <= 0) { setUploadDisable(true); return true; }
          setUploadDisable(false); return false;
        }
      });
    } else { setUploadDisable(false); return false; }
  };

  useEffect(() => {
    comparisonList?.length !== 0 && checkUploadDisable();
  }, [comparisonList]);

  const disableComparison = (comparison: any) => {
    if (comparison.ProjectType !== userData().isOfUserType) {
      if (isRu() && comparison.ProjectType === 0) return true;
      if (isSu() && (comparison.ProjectType === 1 || comparison.ProjectType === 0)) {
        return true;
      }
      if (isIos() && ((comparison.ProjectType === 1 || comparison.ProjectType === 2)
      || (comparison.UserId !== userData().userId))) {
        return true;
      }
      return false;
    }
    if (isIos() && ((comparison.ProjectType === 1 || comparison.ProjectType === 2)
      || (comparison.UserId !== userData().userId))) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.outboxContainer}>
      <AppBarMobile source="other" title={useTranslate('outbox.title')} />
      <div className={styles.outboxListWrapper}>
        {comparisonList?.length > 0 ? comparisonList.map((comparison: any, index) => (
          <div
            id={`${index}-parent`}
            key={`${index.toString()}-${comparison.CompetitorName}`}
            className={`${styles.outboxCard}
             ${shouldHighlight(comparison) && 'highlightCard'} ${disableComparison(comparison) && styles.disabledCard}`}
            tabIndex={index}
            role="button"
            onClick={() => !disableComparison(comparison) && onComparisonClick(index, comparison)}
            onKeyDown={() => !disableComparison(comparison) && onComparisonClick(index, comparison)}
          >
            <div className={styles.cardContentWrapper}>
              {comparison.NoCompetition !== 1 && comparison?.ImageList?.length !== 0 ? (
                <div className={styles.outboxImageWrapper}>
                  <img
                    className={styles.outboxImage}
                    alt={`Img #${index + 1}`}
                    src={comparison.ImageList[0].thumbUrl}
                  />
                </div>
              ) : (
                <div className={`${styles.outboxPlaceholderWrapper} ${disableComparison(comparison) && styles.disabledCard}`}>
                  <SSRIcon paths={IcPlaceholder} />
                </div>
              )}
              <div className={styles.outboxTextWrapper}>
                <Text className={styles.outboxTitle}>{comparison?.CompetitorName}</Text>
                {comparison.NoCompetition !== 1 ? (
                  <Text className={styles.outboxSubtitle}>
                    {`${currency ? `${currency} ` : ''}${comparison?.CriteriaOptionList.find((item: any) => item.CriteriaId === '30').CriteriaValue}`}
                  </Text>
                ) : (
                  <Text className={styles.outboxSubtitle}>
                    {noCompetition}
                  </Text>
                )}
              </div>
              <SSRIcon paths={IcRightArrow} />
            </div>
            <div className={styles.obItemWrapper}>
              <Divider className={styles.obDivider} />
              {comparison?.IsInstantCompare && comparison?.IsInstantCompare === 1 ? (
                <div className={styles.obItemText}>
                  <Text className={styles.outboxSubtitle}>
                    Instant comparison
                  </Text>
                </div>
              ) : (
                <div className={styles.obItemText}>
                  <Text className={styles.outboxSubtitle}>
                    {comparison?.item?.itemNo}
                  </Text>
                  <Text className={styles.outboxSubtitle}>
                    {comparison?.item?.itemName}
                  </Text>
                </div>
              )}
            </div>
            {comparison.NoCompetition !== 1 && comparison?.fileError === true ? (
              <div>
                <Divider className={styles.obDivider} />
                <div className={styles.errorObject}>
                  <div className={styles.icNotice}>
                    <SSRIcon paths={IcNotice} />
                  </div>
                  <Text className={styles.errorMessage}>
                    {maliciousFileMsg}
                  </Text>
                </div>
              </div>
            ) : null}
            {comparison.NoCompetition !== 1 && comparison?.urlError === true ? (
              <div>
                <Divider className={styles.obDivider} />
                <div className={styles.errorObject}>
                  <div className={styles.icNotice}>
                    <SSRIcon paths={IcNotice} />
                  </div>
                  <Text className={styles.errorMessage}>
                    {maliciousUrlMsg}
                  </Text>
                </div>
              </div>
            ) : null}
          </div>
        )) : (
          <div className={styles.emptyTextWrapper}>
            <Text className={styles.emptyText}>{emptyText}</Text>
          </div>
        )}
        <Button
          className={styles.btUpload}
          text="Upload"
          type="emphasised"
          onClick={() => handleUpload(0)}
          disabled={!comparisonList || comparisonList?.length === 0
            || (comparisonList?.length === 1 && disableComparison(comparisonList[0]))
            || uploadDisable === true}
          small
        />
      </div>
    </div>
  );
};
export default OutboxComponent;
