/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Accordion, { AccordionItem } from '@ingka/accordion';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Text from '@ingka/text';
import TextArea from '@ingka/text-area';
import React, { useEffect } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import {
  ComparisonTextArea, ComparisonTextAreaProps, checkInvalidCharRegex, checkValidCharRegex,
} from '../../../../common/constants/constant';
import { isNullEmptyOrUndefined, isValidUrl } from '../../../../common/services/commonService.js';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionDescription, SectionTitle } from '../InstantComparison';
import styles from './OptionalFields.module.css';
import './OptionalFields.css';

interface OptionalFieldsProps {
    material: string | undefined;
    sustainability: string | undefined;
    comment: string | undefined;
    competitorItemName: string | undefined;
    competitorItemUrl: string | undefined;
    urlError: boolean;
    maliciousURLError: boolean;
    setMaterial: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSustainability: React.Dispatch<React.SetStateAction<string | undefined>>;
    setComment: React.Dispatch<React.SetStateAction<string | undefined>>;
    setCompetitorItemName: React.Dispatch<React.SetStateAction<string | undefined>>;
    setCompetitorItemUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    setUrlError: React.Dispatch<React.SetStateAction<boolean>>;
    setMaliciousURLError: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionalFields = ({
  material, sustainability, comment, competitorItemName,
  competitorItemUrl, urlError, maliciousURLError,
  setMaterial, setSustainability, setComment, setCompetitorItemName,
  setCompetitorItemUrl, setUrlError, setMaliciousURLError,
}: OptionalFieldsProps) => {
  const competitorItemUrlTitle = useTranslate('inst.comp.competitorUrlTitle');
  const maliciousUrlMsg = useTranslate('inst.comp.threatUrl');
  const invalidurlMsg = useTranslate('common.details.invalidUrl');
  const textAreaErr = useTranslate('error.textAreaInvalid');

  const OptionalTextAreaValue = (type: ComparisonTextAreaProps) => {
    switch (type.id) {
      case 0:
        return material;
      case 1:
        return sustainability;
      case 2:
        return comment;
      default:
        return '';
    }
  };

  const onOptionalTextAreaChange = (value: string, type: ComparisonTextAreaProps) => {
    const textVal = value.length <= type.charLimit ? value : value.slice(0, type.charLimit);
    if (!isNullEmptyOrUndefined(textVal)) {
      if (checkInvalidCharRegex.test(textVal) === false && checkValidCharRegex.test(textVal) === true) {
        type.textErr = false;
      } else {
        type.textErr = true;
      }
    } else type.textErr = false;
    switch (type.id) {
      case 0:
        setMaterial(textVal);
        break;
      case 1:
        setSustainability(textVal);
        break;
      case 2:
        setComment(textVal);
        break;
      default:
        break;
    }
  };

  const renderOptionalTextArea = (type: any) => (
    <AccordionItem
      id={`icMobile${type.title}`}
      title={type.title}
    >
      <FormField
        shouldValidate={type.textErr}
        validation={{
          id: 'error-msg-textArea',
          msg: textAreaErr,
          type: 'error',
        }}
        className={styles.textArea}
        characterLimit={type.charLimit}
      >
        <TextArea
          id={type.title}
          label={type.label}
          value={OptionalTextAreaValue(type)}
          onChange={(e) => onOptionalTextAreaChange(e.target.value, type)}
        />
      </FormField>
    </AccordionItem>
  );

  const validateCompetitorItemUrl = (url: string) => {
    if (url && url.length > 0 && !isValidUrl(url)) {
      setUrlError(true);
    } else {
      setUrlError(false);
      setMaliciousURLError(false);
    }
  };

  const showWarningIconWithTitle = () => (
    <div className={styles.warningTitleWrapper}>
      <div className={styles.icNotice}>
        <SSRIcon paths={IcNotice} />
      </div>
      <div>
        <Text>{competitorItemUrlTitle}</Text>
      </div>
    </div>
  );

  useEffect(() => {
    maliciousURLError && setUrlError(true);
    return () => {
      ComparisonTextArea.material.textErr = false;
      ComparisonTextArea.sustainability.textErr = false;
      ComparisonTextArea.comment.textErr = false;
    };
  }, []);

  return (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={useTranslate('inst.comp.optionalFieldsTitle')} />
      <SectionDescription sectionDescription={useTranslate('inst.comp.optionalFieldsDesc')} />
      <Accordion className={styles.accordion}>
        {renderOptionalTextArea(ComparisonTextArea.material)}
        {renderOptionalTextArea(ComparisonTextArea.sustainability)}
        {renderOptionalTextArea(ComparisonTextArea.comment)}
        <AccordionItem
          id="icMobileCompetitorItemName"
          title={useTranslate('inst.comp.competitorName')}
          className="icOptionalFields"
        >
          <InputField
            className={styles.inputField}
            id="itemName"
            label={useTranslate('inst.comp.competitorValue')}
            type="text"
            autoComplete="off"
            value={competitorItemName}
            onChange={(e) => {
              const val = e.target.value;
              val.length <= 100 && setCompetitorItemName(e.target.value);
            }}
          />
        </AccordionItem>
        <AccordionItem
          id="icMobileCompetitorItemUrl"
          title={maliciousURLError ? showWarningIconWithTitle() : competitorItemUrlTitle}
          className="icOptionalFields"
        >
          <FormField
            className={styles.inputField}
            shouldValidate={urlError}
            validation={{
              id: 'error-msg-id',
              msg: `${maliciousURLError ? maliciousUrlMsg : invalidurlMsg}`,
              type: 'error',
            }}
          >
            <InputField
              id="itemUrl"
              label={useTranslate('inst.comp.competitorUrlValue')}
              type="text"
              autoComplete="off"
              value={competitorItemUrl}
              onChange={(e) => {
                setCompetitorItemUrl(e.target.value);
                validateCompetitorItemUrl(e.target.value);
              }}
            />
          </FormField>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default OptionalFields;
