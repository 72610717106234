/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';
import { useTranslate } from '../../../../common/services/translationService.js';

interface PillsToolbarProps {
  hfbData: any;
  sort: any;
  project: any;
  onRemoveFilter: (tempSort: any, tempProject: any, newHfbData: any, type: any) => void;
  onClearAll: () => void;
}

export enum FilterType {
  hfb = 'HFB',
  sort = 'Sort',
  project = 'Project'
}

const PillsToolbar = ({
  hfbData,
  sort,
  project,
  onRemoveFilter,
  onClearAll,
}: PillsToolbarProps) => {
  const myProject = useTranslate('project.my.project');
  const clearAll = useTranslate('find.comp.clearAll');
  const removeItemFromArray = (arr:any[], value: any) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const removeFilter = (filterVal: any, type: FilterType) => {
    let tempSort = sort;
    let tempProject = project;
    let tempHfbData = [...hfbData];
    switch (type) {
      case FilterType.hfb:
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.sort:
        tempSort = 'startdate asc';
        break;
      case FilterType.project:
        tempProject = 'all';
        break;
      default: break;
    }
    onRemoveFilter(tempSort, tempProject, tempHfbData, type);
  };

  const fetchSortLabel = (label: any) => {
    switch (label) {
      case 'startdate desc':
        return 'Start date: New to old';
      case 'startdate asc':
        return 'Start date: Old to new';
      case 'enddate desc':
        return 'End date: New to old';
      case 'enddate asc':
        return 'End date: Old to new';
      case 'projectname asc':
        return 'Project name: A - Z';
      case 'projectname desc':
        return 'Project name: Z - A';
      case 'hfbnos asc':
        return 'HFB: 01 - 99';
      case 'hfbnos desc':
        return 'HFB: 99 - 01';
      default:
        return 'Start date: Old to new';
    }
  };

  const buildLabel = (type: FilterType, filter: any) => {
    switch (type) {
      case FilterType.hfb:
        return filter?.label || filter;
      case FilterType.sort:
        return `${type}: ${fetchSortLabel(filter?.label || filter)}`;
      default:
        return `${type}: ${filter?.label || filter}`;
    }
  };

  const renderPills = (filterList: any[], type: FilterType) => filterList?.map((filter: any) => (
    <Pill
      key={`${type}${filter}`}
      className={`${styles.pill} ${styles.pillBorder} noselect`}
      label={buildLabel(type, filter)}
      small
      ssrIcon={IcCross}
      onClick={() => removeFilter(filter, type)}
    />
  ));

  return (
    <div className={styles.pillsContainer}>
      {hfbData?.length > 0 ? (
        renderPills(hfbData, FilterType.hfb)
      ) : null}
      {(sort?.length > 0 && sort !== 'startdate asc') ? (
        renderPills([sort], FilterType.sort)
      ) : null}
      {project === 'my' ? (
        renderPills([myProject], FilterType.project)
      ) : null}
      <Pill
        className={`${styles.pill} noselect`}
        label={clearAll}
        small
        onClick={onClearAll}
      />
    </div>
  );
};

export default PillsToolbar;
