/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from '@ingka/text';
import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Button from '@ingka/button';
import { useLocation, useNavigate } from 'react-router-dom';
import ChildItemsTable from '../childItemsTable/ChildItemsTable';
import { useTranslate } from '../../../../common/services/translationService.js';
import {
  getFullUrl, isNullEmptyOrUndefined,
  userData,
} from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import './CustomUnit.css';
import useCallApi from '../../../../common/services/apiService.js';
import CustomUnitDetailsTab from '../customUnitDetailTab/CustomUnitDetailTab';
import UnitImageModal from '../../viewUnit/unitImageModal/UnitImageModal';
import { appRoutes } from '../../../../common/constants/constant';

const RenderImages = ({ images, preview }: any) => {
  const [imgList, setImgList] = useState<any>([]);
  const [imageToken, setImageToken] = useState<any>();
  const { get } = useCallApi();
  const location: any = useLocation();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isEditPrjt = location.state?.isEdit || false;

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const getImg = async () => {
    let imgeList:any = [];
    imgeList = await Promise.all(
      images?.map(async (image: any) => {
        if (typeof image === 'string' && !image?.includes('http')) {
          const val: any = await offlineData.getItem(image);
          return val instanceof Blob ? val : new Blob([val.originFileObj]);
        }
        return image;
      }),
    );
    const imgPromises = imgeList.map((file:any) => new Promise((resolve, reject) => {
      if (!isNullEmptyOrUndefined(file) && ((((!isNullEmptyOrUndefined(file?.url) && !file?.url?.includes('http')) || ((typeof (file) !== 'object') && !file?.includes('http')))) || !isNullEmptyOrUndefined(file?.size))) {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      } else resolve(file);
    }));
    const imgResults = await Promise.all(imgPromises);
    setImgList(imgResults);
  };

  useEffect(() => {
    getSasToken();
    const fetchImages = async () => {
      const fetchList = await Promise.all(
        images.map(async (image: any) => {
          const val: any = await offlineData.getItem(image);
          return val instanceof Blob ? val : new Blob([val.originFileObj]);
        }),
      );

      const imgPromises = fetchList.map((file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }));

      const imgResults = await Promise.all(imgPromises);
      setImgList(imgResults);
    };
    if (preview === true && isEditPrjt === false) {
      fetchImages();
    } else {
      getImg();
    }
  }, [images]);

  const handleImageClick = (imageIndex: number) => {
    // Transform array to match the component input
    const transformedList = imgList?.map((url: any) => ({ imagePath: url }));
    setSelectedItem(transformedList);
    setCurrentImageIndex(imageIndex);
    setOpenImageModal(true);
  };

  const closeImageModal = () => {
    setOpenImageModal(false);
    setSelectedItem(null);
    setCurrentImageIndex(0);
  };

  return (
    <>
      <div className="cuoImgWrapper">
        {imgList?.map((img: any, index: number) => (
          <img
            className={`cuoImg ${!preview ? 'cnImgCursor' : ''}`}
            src={(preview !== true && isEditPrjt !== true)
              ? img.concat(imageToken?.sasToken) : img.url || img}
            alt={`Img${index.toString()}`}
            key={`Img${index.toString()}`}
            onClick={!preview ? () => handleImageClick(index) : undefined}
          />
        ))}
      </div>

      {!preview && openImageModal && selectedItem && (
        <UnitImageModal
          imageList={selectedItem}
          imageToken={imageToken}
          currentImageIndex={currentImageIndex}
          openImageModal={openImageModal}
          onCloseImageModal={closeImageModal}
        />
      )}
    </>
  );
};

interface CustomUnitProps {
  detailsTab: any;
  itemsTab: any;
  generic?: any;
  isPreview?: boolean;
  saveUnit?: () => any;
  unitData?: any;
  isEditPrjt?: boolean;
}

const CustomUnit = ({
  detailsTab, itemsTab, generic, isPreview, saveUnit, unitData, isEditPrjt,
}: CustomUnitProps) => {
  const cuoNumber = useTranslate('customUnit.overview.cuoNumber');
  const cuoReferenceUrl = useTranslate('customUnit.overview.referenceUrl');
  const cuoComments = useTranslate('customUnit.details.commentsLabel');
  const cuoRefImg = useTranslate('customUnit.overview.refImg');
  const cuoItems = useTranslate('customUnit.overview.items');
  const cuoSubmit = useTranslate('common.createCustomUnit');
  const cuoUpdate = useTranslate('customUnit.overview.update');
  const edtBtnText = useTranslate('customUnit.edit');

  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(appRoutes.createUnit, {
      state: { unitData, isEdit: true },
    });
  };

  return (
    <div className="cuoContainer">
      <Text className="cuoTitle">{detailsTab?.Name}</Text>
      <div className="cuoSectionWrapper cuoTopWrapper">
        {isPreview !== true ? (
          <>
            <div className="cuoSection">
              <Text className="cuoLabel">{cuoNumber}</Text>
              <Text className="cuoValue">{generic?.unitNo}</Text>
            </div>
            <Divider type="vertical" />
          </>
        ) : null}
        <CustomUnitDetailsTab
          detailsTab={detailsTab}
          itemsTab={itemsTab}
          isPreview={isPreview}
        />
        {isPreview === false ? (
          <>
            <Divider type="vertical" />
            <Button
              className="cuoBtnEdit"
              text={edtBtnText}
              type="secondary"
              onMouseDown={(e) => { e.preventDefault(); }}
              onClick={onEditClick}
              disabled={generic?.userId !== userData().userId}
              small
            />
          </>
        ) : null}
      </div>
      <div className="cuoSectionWrapper cuoMidWrapper">
        {!isNullEmptyOrUndefined(detailsTab?.PiaLink) ? (
          <div className="cuoSection">
            <Text className="cuoLabel">{cuoReferenceUrl}</Text>
            <div className="cuoValue cuoUrl">
              <a href={getFullUrl(detailsTab?.PiaLink)} target="_blank" rel="noopener noreferrer">
                {detailsTab?.PiaLink}
              </a>
            </div>
          </div>
        ) : null}
        <Accordion className="cuoAccordion">
          {!isNullEmptyOrUndefined(detailsTab?.Comments) ? (
            <AccordionItem id="cuoComments" title={cuoComments}>
              <Text className="cuoValue">{detailsTab?.Comments}</Text>
            </AccordionItem>
          ) : null}
          {detailsTab?.Images?.length > 0 ? (
            <AccordionItem id="cuoImages" title={`${cuoRefImg} (${detailsTab?.Images?.length})`}>
              <RenderImages images={detailsTab?.Images} preview={isPreview} />
            </AccordionItem>
          ) : null}
          <AccordionItem
            id="cuoItems"
            open
            title={`${cuoItems} (${itemsTab?.importedItems?.length > 0 ? itemsTab?.importedItems?.length : 0})`}
          >
            <ChildItemsTable
              data={itemsTab?.importedItems}
              displayTitleBar={false}
              displayLabel={false}
              key="cuoChildItems"
              name={detailsTab?.Name}
              enableReplace={false}
            />
          </AccordionItem>
        </Accordion>
      </div>
      {isPreview === true ? (
        <div className="cuoBtnWrapper">
          <Button
            className="cuoBtnSubmit"
            type="emphasised"
            text={isEditPrjt === true ? cuoUpdate : cuoSubmit}
            onClick={saveUnit}
          />
        </div>
      ) : null}
    </div>
  );
};

CustomUnit.defaultProps = {
  generic: undefined,
  isPreview: false,
  saveUnit: undefined,
  unitData: undefined,
  isEditPrjt: false,
};

export default CustomUnit;
