/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';
import { useTranslate } from '../../../../../common/services/translationService.js';

interface PillsToolbarProps {
  data: any;
  hfbData: any;
  rocList: any;
  priceClass: any;
  strategicPricing: any;
  setCountrySpecific: React.Dispatch<React.SetStateAction<boolean>>;
  onRemoveFilter: (newPayload: any, newHfbData: any) => void;
  onClearAll: () => void;
}

enum FilterType {
  hfb = 'HFB',
  pra = 'PRA',
  pa = 'PA',
  itemType = 'Item type',
  roc = 'ROC',
  sp = 'Strategic pricing',
  pc = 'Price class',
  cs = 'Country specific'
}

const PillsToolbar = ({
  data,
  hfbData,
  rocList,
  priceClass,
  strategicPricing,
  setCountrySpecific,
  onRemoveFilter,
  onClearAll,
}: PillsToolbarProps) => {
  const removeItemFromArray = (arr:any[], value: any) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const removeFilter = (filterVal: any, type: FilterType) => {
    const tempPayload = { ...data };
    let tempHfbData = [...hfbData];
    switch (type) {
      case FilterType.hfb:
        tempPayload.HfbId = removeItemFromArray(tempPayload?.HfbId, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.pra:
        tempPayload.PraId = removeItemFromArray(tempPayload?.PraId, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.pa:
        tempPayload.PaId = removeItemFromArray(tempPayload?.PaId, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.itemType:
        tempPayload.ItemTypeFilter = removeItemFromArray(tempPayload?.ItemTypeFilter, filterVal);
        break;
      case FilterType.roc:
        tempPayload.RocNos = removeItemFromArray(tempPayload?.RocNos, filterVal);
        break;
      case FilterType.pc:
        tempPayload.PcNo = removeItemFromArray(tempPayload?.PcNo, filterVal);
        break;
      case FilterType.sp:
        tempPayload.SpNo = removeItemFromArray(tempPayload?.SpNo, filterVal);
        break;
      case FilterType.cs:
        setCountrySpecific(!data?.IsCountrySpecific);
        break;
      default: break;
    }
    onRemoveFilter(tempPayload, tempHfbData);
  };

  const buildLabel = (type: FilterType, filter: any) => {
    if ([FilterType.hfb,
      FilterType.pra,
      FilterType.pa,
      FilterType.cs].includes(type)) {
      return filter?.label || filter;
    }
    return `${type}: ${filter?.label || filter}`;
  };

  const renderPills = (filterList: any[], type: FilterType) => {
    let valueArray = [...filterList];
    switch (type) {
      case FilterType.roc:
        valueArray = rocList.filter((roc: any) => filterList.includes(roc.value));
        break;
      case FilterType.pc:
        valueArray = priceClass.filter((pc: any) => filterList.includes(pc.value));
        break;
      case FilterType.sp:
        valueArray = strategicPricing.filter((sp: any) => filterList.includes(sp.value));
        break;
      default: break;
    }

    return valueArray?.map((filter: any) => (
      <Pill
        key={`${type}${filter?.label || filter}`}
        className={`${styles.pill} ${styles.pillBorder} noselect`}
        label={buildLabel(type, filter)}
        small
        ssrIcon={IcCross}
        onClick={() => removeFilter(filter?.value || filter, type)}
      />
    ));
  };

  return (
    <div className={styles.pillsContainer}>
      {data?.HfbId?.length > 0 ? (
        renderPills(data?.HfbId, FilterType.hfb)
      ) : null}
      {data?.PraId?.length > 0 ? (
        renderPills(data?.PraId, FilterType.pra)
      ) : null}
      {data?.PaId?.length > 0 ? (
        renderPills(data?.PaId, FilterType.pa)
      ) : null}
      {data?.ItemTypeFilter?.length > 0 ? (
        renderPills(data?.ItemTypeFilter, FilterType.itemType)
      ) : null}
      {data?.RocNos?.length > 0 ? (
        renderPills(data?.RocNos, FilterType.roc)
      ) : null}
      {data?.PcNo?.length > 0 ? (
        renderPills(data?.PcNo, FilterType.pc)
      ) : null}
      {data?.SpNo?.length > 0 ? (
        renderPills(data?.SpNo, FilterType.sp)
      ) : null}
      {data?.IsCountrySpecific === true ? (
        renderPills([FilterType.cs], FilterType.cs)
      ) : null}
      <Pill
        className={`${styles.pill} noselect`}
        label={useTranslate('find.comp.clearAll')}
        small
        onClick={onClearAll}
      />
    </div>
  );
};

export default PillsToolbar;
