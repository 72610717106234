/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Accordion, { AccordionItem } from '@ingka/accordion';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import React, { useEffect, useState } from 'react';
import Select, { Option } from '@ingka/select';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import IcPublish from '@ingka/ssr-icon/paths/arrow-up-from-base';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import IcSave from '@ingka/ssr-icon/paths/document-checkmark';
import { useNavigate } from 'react-router-dom';
import flags from '../../../../common/services/flags';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Details } from '../detailsTab/DetailsTab';
import { Item } from '../items/Items';
import { RetailUnit } from '../retailUnitsTab/RetailUnits';
import '@ingka/inline-message/dist/style.css';
import styles from './PreviewModal.module.css';
import './PreviewModal.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import { saveDraftProject, saveRuProject } from '../topNavigation/TopNavigation';
import useCallApi from '../../../../common/services/apiService.js';
import {
  createProj, createProjFullReset, setToast, setLoader,
} from '../../../../store/reducers/create-proj-reducer';
import { appRoutes, projStatus } from '../../../../common/constants/constant';
import { SellingUnit } from '../sellingUnitsTab/SellingUnits';
import {
  compressJson,
  convertToBase64,
  isIos, isNullEmptyOrUndefined, isRu, userData, userRu,
} from '../../../../common/services/commonService.js';
import CloseModal from '../../../../common/components/closeModal/CloseModal';

interface PreviewModalProps {
    isVisible: boolean;
    onClose: () => void;
    projtType: string;
    ruCount: number;
    projDetails:any;
    isEditProject: boolean;
    itemDetails: any;
    ruDetails: any;
    suDetails: any;
    isCopy: boolean;
}

const PreviewModal = ({
  isVisible, onClose, projtType, projDetails, ruCount,
  isEditProject, itemDetails, ruDetails, suDetails, isCopy,
}: PreviewModalProps) => {
  const projectStore = useAppSelector((state) => state?.createProjReducer?.createProjData);
  const prevProjectStore = useAppSelector((state) => state.createProjReducer.prevProjData);
  const RU = useAppSelector((state) => state?.createProjReducer?.retailUnits);
  const SU = useAppSelector((state) => state?.createProjReducer?.sellingUnits);
  const prevItemsData = useAppSelector((state) => state?.createProjReducer?.prevItemsData);
  const saveValid = useAppSelector((state) => state?.createProjReducer?.saveValid);
  const submitValid = useAppSelector((state) => state?.createProjReducer?.submitValid);
  const detailsTab: Details = projectStore?.detailsTab;
  const selectedItems: Item[] = projectStore?.itemsTab?.selectedItems;
  const prevSelectedItems: Item[] = prevProjectStore?.itemsTab?.selectedItems;
  const activeAcceptedItems = projectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isActive === true);
  const selectedRu: RetailUnit[] = projectStore?.ruTab?.selectedRu;
  const selectedSu: SellingUnit[] = projectStore?.suTab?.selectedSu;
  const [unsoldItemExists, setUnsoldItemExists] = useState<boolean>(false);
  const globalObject = {
    key: 111,
    ruName: 'Global',
    ruCode: 'global',
    ruImage: '',
    clusterCode: '',
    clusterName: '',
  };
  const ruDropDown: RetailUnit[] = [globalObject, ...selectedRu];
  const [selectedRuDropdown, setSelectedRuDropdown] = useState<string>('0');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const emptyMessage = useTranslate('create.proj.emptyMessage');
  const ruIncludedInTheProject = useTranslate('proj.details.retailUnitsIncluded');
  const suIncludedInTheProject = useTranslate('proj.details.sellingUnitsIncluded');
  const additionalMessage = useTranslate('create.proj.additionalMessageTitle');
  const outOf = useTranslate('create.proj.outOf');
  const noticeDescription = useTranslate('create.proj.noticeDescription');
  const allRetailUnitsTitle = useTranslate('proj.details.allRetailUnits');
  const allSellingUnitsTitle = useTranslate('proj.details.allSellingUnits');
  const submitProjectSuccess = useTranslate('create.proj.submit_proj');
  const submitProjectFail = useTranslate('create.proj.submit_proj_fail');
  const savedRuName: any = localStorage.getItem('selectedRetailUnit');
  const ruName = !isNullEmptyOrUndefined(savedRuName) ? JSON.parse(savedRuName).ruName : '';
  const { post, postCompressedFile } = useCallApi();
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);
  const [removedItemsCount, setRemovedItemsCount] = useState<number>(0);
  const sellingUnitEmptyMessage = useTranslate('create.proj.sellingUnit_emptyMessage');
  const [itemsCount, setItemsCount] = useState<number>(0);

  const isDraftProj = () => (projtType === projStatus.draft);

  const isAcceptedProj = () => (projtType === projStatus.accepted);

  const resetData = (isDropdownDefault: any) => {
    isDropdownDefault && dispatch(setLoader(true));
    setSelectedRuDropdown('0');
    const projData = ({
      ...projectStore,
      itemsTab: { selectedItems: (isDraftProj() || isEditProject) ? prevSelectedItems : prevItemsData },
    });
    dispatch(createProj(projData));
    setUnsoldItemExists(false);
    isDropdownDefault && setTimeout(() => {
      dispatch(setLoader(false));
    }, 400);
  };

  const navigateToDashboard = () => {
    navigate(
      appRoutes.dashboard,
      {
        state: {
          dashboardFilters: true,
        },
      },
    );
  };

  const onModalClose = () => {
    !(isDraftProj() || isEditProject || isAcceptedProj()) && resetData(false);
    onClose();
  };

  const fetchConvertedRu = (payload: any) => {
    dispatch(setLoader(true));
    post(
      'converted-ru-items',
      payload,
      (response: Item[]) => {
        const projData = ({ ...projectStore, itemsTab: { selectedItems: response } });
        dispatch(createProj(projData));
        const ifUnsoldItem = response.some((item: Item) => item.isHighLighted === true);
        setUnsoldItemExists(ifUnsoldItem);
        dispatch(setLoader(false));
      },
      () => {
        dispatch(setLoader(false));
      },
    );
  };

  const onRuSelect = (value: any) => {
    if (value !== '0') {
      setSelectedRuDropdown(value);
      const itemNoList = (isEditProject) ? selectedItems.map((item: Item) => item.itemNo)
        : prevItemsData.map((item: Item) => item.itemNo);
      const selectedRuCode = selectedRu[parseInt(value, 10) - 1].ruCode;
      const payload = {
        ruCode: selectedRuCode,
        ItemNoList: itemNoList,
        GProjectId: (isDraftProj() || isEditProject) ? projDetails?.GProjectId : null,
      };
      fetchConvertedRu(payload);
    } else {
      resetData(true);
    }
  };

  const checkItemStatus = () => {
    if (projDetails && isIos()) {
      const itemNoList = prevItemsData.map((item: Item) => item.itemNo);
      const selectedRuCode = projDetails?.RuCode;
      const payload = { ruCode: selectedRuCode, ItemNoList: itemNoList, GProjectId: null };
      fetchConvertedRu(payload);
    }
  };

  const getItemsCount = () => {
    if ((isRu() && projDetails?.hasParentProject) || (isCopy && !isIos())) {
      return activeAcceptedItems?.length || 0;
    }
    return selectedItems?.length || 0;
  };

  useEffect(() => {
    (isAcceptedProj() && isVisible) && checkItemStatus();
    const removedItems = prevProjectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isActive === false);
    setRemovedItemsCount(removedItems?.length);
    setItemsCount(getItemsCount());
  }, [isVisible]);

  const saveAcceptedProjectApi = (compressedContent: any) => {
    const formData = new FormData();
    formData.append('gZipRequest', compressedContent);
    postCompressedFile('save-accepted-project', formData, () => {
      dispatch(setToast({ openToast: true, toastLabel: submitProjectSuccess }));
      onModalClose();
      dispatch(createProjFullReset());
      navigateToDashboard();
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: submitProjectFail }));
    });
  };

  const submitAccepted = () => {
    const acceptPayload = {
      retailUnitProjectId: projDetails?.RetailUnitProjectId,
      ItemNoActiveList: selectedItems?.filter((item) => item.isActive !== false && item.isRuItem !== 1).map(
        (val) => ({ ItemNo: val.itemNo, ItemType: val.itemType }),
      ),
      ItemNoRuList: selectedItems?.filter((item) => item.isRuItem === 1).map(
        (val) => ({ ItemNo: val.itemNo, ItemType: val.itemType }),
      ),
      SuCodeList: selectedSu?.map((val) => val.unitCode),
      UserId: userData()?.userId,
      RuCode: projDetails?.RuCode,
      RetailUnitProjectBasic: {
        AdditionalMessage: convertToBase64(detailsTab?.AdditionalMsg),
        EndDate: detailsTab?.EndDate,
        StatusNo: 20,
      },
      RupSplitSellingUnit: projectStore?.suTab?.selectedSu?.length > 0
        ? projectStore?.selectedSuItems?.filter((suItem: any) => suItem.isActive === true)
        : [],
    };
    compressJson(acceptPayload).then((value) => {
      saveAcceptedProjectApi(value);
    });
  };

  const saveProject = () => {
    if (isRu()) {
      let projDataRU = [];
      if (isCopy && !isIos()) {
        projDataRU = ({ ...projectStore, itemsTab: { selectedItems: activeAcceptedItems } });
        dispatch(createProj(projDataRU));
      }
      const prjStr = (isCopy && !isIos()) ? projDataRU : projectStore;
      saveRuProject(projtType, projDetails, prjStr, dispatch, post, 'save');
    } else saveDraftProject(projectStore, dispatch, post);
  };

  const submitProject = () => {
    if (isAcceptedProj()) {
      if (selectedSu?.length !== 0) {
        submitAccepted();
      } else {
        setOpenSubmitModal(true);
      }
    } else if (isRu()) {
      if (selectedSu?.length !== 0) {
        let projDataSubmit = [];
        if (isCopy && !isIos()) {
          projDataSubmit = ({ ...projectStore, itemsTab: { selectedItems: activeAcceptedItems } });
          dispatch(createProj(projDataSubmit));
        }
        const prjStrSubmit = (isCopy && !isIos()) ? projDataSubmit : projectStore;
        saveRuProject(projtType, projDetails, prjStrSubmit, dispatch, post, 'submit', onModalClose, navigateToDashboard);
      } else {
        setOpenSubmitModal(true);
      }
    } else saveDraftProject(projectStore, dispatch, post, prevItemsData, 'submit', navigateToDashboard);
  };

  const renderPaNo = (text: any, record: any) => {
    const isActive = record?.isActive;
    const isRuItem = record?.isRuItem === 1;
    return (
      !isRuItem && !isActive && isRu() && (isAcceptedProj() || projDetails?.hasParentProject) ? (
        <div className="table-number removeItem">
          {record?.isHighLighted && <SSRIcon className={styles.noticeIcon} paths={IcNotice} /> }
          {text}
        </div>
      ) : (
        <div className="table-number">
          {record?.isHighLighted && <SSRIcon className={styles.noticeIcon} paths={IcNotice} /> }
          {text}
        </div>
      )
    );
  };

  const renderItemName = (text: any, record: any) => {
    const isActive = record?.isActive;
    const isRuItem = record?.isRuItem === 1;
    return (
      !isRuItem && !isActive && isRu() && (isAcceptedProj() || projDetails?.hasParentProject) ? (
        <div className={styles.noticeContainer}>
          <div className="removeItem">{text}</div>
        </div>
      ) : (
        <div className={styles.noticeContainer}>
          {text}
        </div>
      )
    );
  };

  const renderItemType = (text: any, record: any) => {
    const isActive = record?.isActive;
    const isRuItem = record?.isRuItem === 1;
    return (
      !isRuItem && !isActive && isRu() && (isAcceptedProj() || projDetails?.hasParentProject) ? (
        <div className="removeItem">{text}</div>
      ) : (
        <div>{text}</div>
      )
    );
  };

  const renderItemNo = (text: any, record: any) => {
    const isActive = record?.isActive;
    const isRuItem = record?.isRuItem === 1;
    return (
      !isRuItem && !isActive && isRu() && (isAcceptedProj() || projDetails?.hasParentProject) ? (
        <div className="table-number removeItem itemNo-common-design">{text}</div>
      ) : (
        <div className="table-number itemNo-common-design">{text}</div>
      )
    );
  };

  const columns: ColumnsType<Item> = [
    {
      key: 1,
      title: useTranslate('tab.items.paNo'),
      dataIndex: 'paNo',
      width: '10%',
      className: 'table-number',
      render: (text: any, record: any) => renderPaNo(text, record),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: useTranslate('tab.items.item_name'),
      dataIndex: 'itemName',
      width: '50%',
      render: (text: any, record: any) => renderItemName(text, record),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: useTranslate('tab.items.type'),
      dataIndex: 'itemType',
      width: '15%',
      className: 'table-header-padding',
      render: (text: any, record: any) => renderItemType(text, record),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: useTranslate('tab.items.item'),
      dataIndex: 'itemNo',
      width: '20%',
      className: 'table-number',
      render: (text: any, record: any) => renderItemNo(text, record),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  const renderSellingUnits = () => {
    if (selectedSu?.length === 0) {
      return (
        <Text className={styles.noMessage}>{sellingUnitEmptyMessage}</Text>
      );
    }
    if (selectedSu.length !== SU.length) {
      return (
        <>
          {selectedSu.map((suItem: SellingUnit, index: number) => (
            <div className={styles.ruItem}>
              <span className={styles.ruName}>
                {suItem.unitCode}
                &nbsp; - &nbsp;
                {suItem.unitName}
              </span>
              {index !== selectedSu.length - 1 && (
              <hr className="demo-divider__hr-vertical divider_vertical" />
              )}
            </div>
          ))}
        </>
      );
    }
    return (
      <div className={styles.ruItem}>
        <span className={styles.ruName}>
          {`${allSellingUnitsTitle} in ${ruName}`}
        </span>
      </div>
    );
  };

  const renderRetailUnits = () => {
    if (selectedRu.length !== ruCount) {
      return (
        <>
          {selectedRu.map((ruItem: RetailUnit, index: number) => (
            <div className={styles.ruItem}>
              <span className={styles.ruName}>
                <img className="table-image-left" src={flags[ruItem.ruCode]} alt="" />
                {ruItem.ruName}
              </span>
              {index !== selectedRu.length - 1 && (
              <hr className="demo-divider__hr-vertical divider_vertical" />
              )}
            </div>
          ))}
        </>
      );
    }
    return (
      <div className={styles.ruItem}>
        <span className={styles.ruName}>
          {allRetailUnitsTitle}
        </span>
      </div>
    );
  };

  const handleSubmitModal = (type?:number) => {
    if (type === 2) {
      if (isAcceptedProj()) {
        submitAccepted();
      } else if (isRu()) {
        let projDataSubmit = [];
        if (isCopy && !isIos()) {
          projDataSubmit = ({ ...projectStore, itemsTab: { selectedItems: activeAcceptedItems } });
          dispatch(createProj(projDataSubmit));
        }
        const prjSubmit = (isCopy && !isIos()) ? projDataSubmit : projectStore;
        saveRuProject(projtType, projDetails, prjSubmit, dispatch, post, 'submit', onModalClose, navigateToDashboard);
      }
    }
    setOpenSubmitModal(false);
  };

  const getProjName = () => {
    if (isIos()) {
      return projDetails?.GProjectName?.replace('IoS-', '');
    }
    if (isRu() && !isAcceptedProj()) {
      return projDetails?.RetailUnitProjectName?.replace(`${userRu()}-`, '');
    }
    return projDetails?.RetailUnitProjectName;
  };

  const isEqualProjDetails = () => {
    const projectInfo = {
      Name: getProjName(),
      StartDate: projDetails?.projectStartDate,
      EndDate: projDetails?.projectEndDate,
      Message: projDetails?.message,
      AdditionalMsg: projDetails?.additionalMessage || '',
      ProjectId: isIos() ? projDetails?.GProjectId : projDetails?.RetailUnitProjectId,
    };
    if (isRu() && projDetails?.hasParentProject === true && isAcceptedProj()) {
      return false;
    }
    return (JSON.stringify(projectInfo) !== JSON.stringify(projectStore?.detailsTab));
  };

  const isEqualSelectedItems = () => {
    if (isRu() && projDetails?.hasParentProject === true && !isAcceptedProj()) {
      return ((JSON.stringify(itemDetails) !== JSON.stringify(selectedItems)) || removedItemsCount > 0);
    }
    if (isRu() && projDetails?.hasParentProject === true && isAcceptedProj()) {
      return (removedItemsCount > 0);
    }
    return (itemDetails.length !== selectedItems.length);
  };

  const isEqualSelectedRu = () => (ruDetails.length !== selectedRu.length);

  const isEqualSelectedSu = () => {
    if (isRu() && projDetails?.statusNo === 40) {
      return false;
    }
    if (isRu() && projDetails?.hasParentProject === false) {
      return (suDetails?.length !== selectedSu?.length);
    }
    return (JSON.stringify(projectStore?.suTab) !== JSON.stringify(prevProjectStore?.suTab));
  };

  const showIosWarningMessages = () => (
    <>
      { isEditProject && (isEqualProjDetails() || isEqualSelectedItems() || isEqualSelectedRu()) ? (
        <div className={styles.waringTitleWrapper}>
          <InlineMessage
            body="Please note! Receivers will not be notified of your changes. You need to communicate this separately."
            title="Warning"
            className={styles.warningText}
            variant="cautionary"
          />
        </div>
      ) : null }
    </>
  );

  const ruHandlingIOSMessages = () => {
    if (removedItemsCount > 0) {
      return `${removedItemsCount > 0 ? `You have removed ${removedItemsCount} ${removedItemsCount > 1 ? 'items' : 'item'} from the project.` : ''}
      ${(isEqualProjDetails() || isEqualSelectedSu())
    ? 'Please note! Receivers will not be notified of your changes. You need to communicate this separately.' : ''}`;
    }
    return 'Please note! Receivers will not be notified of your changes. You need to communicate this separately.';
  };

  const ruAcceptedWarningMessages = () => (
    `${removedItemsCount > 0 ? `You have removed ${removedItemsCount} ${removedItemsCount > 1 ? 'items' : 'item'} from the project.` : ''}`
  );

  const buildItems = () => {
    const ruItems = selectedItems.filter((item: any) => item?.isRuItem === 1);
    const filterItems = prevSelectedItems.filter((val:any) => !ruItems.some((i) => i.itemNo === val.itemNo));
    return [...filterItems, ...ruItems];
  };

  const buildItemsCount = () => {
    const iosItems = prevSelectedItems?.filter((item: any) => item?.isRuItem !== 1);
    const ruItems = selectedItems.filter((item: any) => item?.isRuItem === 1);
    return [...iosItems, ...ruItems]?.length;
  };

  const showRuWaringMessages = () => {
    if (isEditProject || isAcceptedProj()) {
      return (
        <>
          { projDetails?.hasParentProject && (isEqualProjDetails() || isEqualSelectedItems() || isEqualSelectedSu()) ? (
            <div className={styles.waringTitleWrapper}>
              <InlineMessage
                body={(projDetails?.hasParentProject && isAcceptedProj() && isEqualSelectedItems())
                  ? ruAcceptedWarningMessages() : ruHandlingIOSMessages()}
                title="Warning"
                className={styles.warningText}
                variant="cautionary"
              />
            </div>
          )
            : (
              <>
                { (isEqualProjDetails() || isEqualSelectedItems() || isEqualSelectedSu()) ? (
                  <div className={styles.waringTitleWrapper}>
                    <InlineMessage
                      body="Please note! Receivers will not be notified of your changes. You need to communicate this separately."
                      title="Warning"
                      className={styles.warningText}
                      variant="cautionary"
                    />
                  </div>
                ) : null }
              </>
            ) }
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <Modal
        className="pmParent"
        visible={isVisible}
        focusLockProps={{
          disabled: true,
        }}
        handleCloseBtn={onModalClose}
      >
        <Theatre
          aria-label="Accessibility header for a modal"
          header={<ModalHeader ariaCloseTxt="Close button text" title={detailsTab.Name} />}
        >
          <div className={styles.contentWrapper}>
            {isIos() ? showIosWarningMessages() : showRuWaringMessages()}
            <div className={styles.dateTitleWrapper}>
              <Text className={styles.sectionTitle}>
                {useTranslate('tab.details.start_date')}
              </Text>
              <Text className={styles.sectionTitle}>
                {useTranslate('tab.details.end_date')}
              </Text>
            </div>
            <div className={styles.dateWrapper}>
              <Text className={styles.date}>
                {detailsTab?.StartDate || 'Not Set'}
              </Text>
              <Text className={styles.date}>
                {detailsTab?.EndDate || 'Not Set'}
              </Text>
            </div>
            <Text className={styles.sectionTitle}>{useTranslate('create.proj.messageTitle')}</Text>
            {detailsTab.Message?.length > 0 ? (
              <Text className={styles.message}>{detailsTab.Message}</Text>
            ) : (
              <Text className={styles.noMessage}>{emptyMessage}</Text>
            )}
            {(isAcceptedProj() || isEditProject === true) && detailsTab.AdditionalMsg?.length > 0 ? (
              <>
                <Text className={styles.sectionTitle}>{additionalMessage}</Text>
                <Text className={styles.message}>{detailsTab.AdditionalMsg}</Text>
              </>
            ) : null}
            <Accordion>
              {(isAcceptedProj() || isRu()) ? (
                <AccordionItem
                  caption=""
                  id="unique-id_2"
                  subtitle=""
                  title={`${suIncludedInTheProject} (${selectedSu?.length || 0} ${outOf} ${SU?.length})`}
                >
                  {renderSellingUnits()}
                </AccordionItem>
              )
                : (
                  <AccordionItem
                    caption=""
                    id="unique-id_2"
                    subtitle=""
                    title={`${ruIncludedInTheProject} (${selectedRu?.length || 0} ${outOf} ${RU?.length})`}
                  >
                    {renderRetailUnits()}
                  </AccordionItem>
                )}
            </Accordion>
            <hr className="demo-divider__hr-horizontal" />
            <div className={styles.itemsContainer}>
              <div className={styles.itemsHeader}>
                <div className={styles.itemTitleWrapper}>
                  <Text className={styles.itemTitle}>
                    {`Items (${(isAcceptedProj() || projDetails?.hasParentProject) ? buildItemsCount() : itemsCount})`}
                  </Text>
                  {unsoldItemExists && (
                  <div className={styles.noticeContainer}>
                    <SSRIcon className={styles.noticeIcon} paths={IcNotice} />
                    <Text className={styles.noticeText}>
                      {noticeDescription}
                    </Text>
                  </div>
                  )}
                </div>
                {(isIos() && !isAcceptedProj()) && (
                <Select
                  id="RUselect"
                  className={styles.select}
                  value={selectedRuDropdown}
                  onChange={(e) => onRuSelect(e.target.value)}
                  hintText=""
                >
                  {ruDropDown.map((ru, index) => (
                    <Option value={index.toString()} name={ru.ruName} />
                  ))}
                </Select>
                )}
              </div>
              <Table
                className="table-striped-rows"
                columns={columns}
                dataSource={(isAcceptedProj() || projDetails?.hasParentProject)
                  ? buildItems() : (isCopy && !isIos()) ? activeAcceptedItems : selectedItems}
                pagination={false}
                size="middle"
                rowKey={(record: any) => record?.itemNo}
              />
            </div>
            <div className={styles.footer}>
              <Button
                className={styles.actionBtn}
                text={useTranslate('create.proj.saveTitle')}
                ssrIcon={IcSave}
                disabled={saveValid !== true}
                onClick={() => saveProject()}
              />
              <Button
                className={styles.actionBtn}
                text={useTranslate('create.proj.submitTitle')}
                type="primary"
                disabled={submitValid !== true}
                onClick={() => submitProject()}
                ssrIcon={IcPublish}
              />
            </div>
          </div>
        </Theatre>
      </Modal>
      <CloseModal
        modelOpen={openSubmitModal}
        closeModal={handleSubmitModal}
        saveValid
        closeText={useTranslate('proj.details.delete.confirmation.negetive')}
        saveText={useTranslate('create.proj.submitModal_savetext')}
        title={useTranslate('common.confirmation.title')}
        subText={useTranslate('create.proj.submitModal_subtext')}
      />
    </div>
  );
};

export default PreviewModal;
