/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './ruSection.module.css';
import { offlineData } from '../../../../common/configs/offlineData';

interface RuSectionProps {
  selectedRu: any;
  isEdit: boolean;
  setSelectedRU: (value:any) => void;
}

const RuSection = ({
  selectedRu, isEdit, setSelectedRU,
}: RuSectionProps) => {
  const [ruList, setRuList] = useState([]);

  useEffect(() => {
    offlineData.getItem('retailUnits').then((data: any) => {
      setRuList(data);
    });
  }, []);

  const onRuSelect = (val:any) => {
    offlineData.setItem('selectedRetailUnit', { ruVal: val.ruCode, refRuCode: null });
    setSelectedRU(val);
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.titleWrapper}>
        <SectionTitle sectionTitle={useTranslate('tab.ru.retail_unit')} />
      </div>
      <Select
        showSearch
        className={styles.ruSelect}
        options={ruList}
        placeholder={useTranslate('inst.comp.ruPlaceholder')}
        fieldNames={{
          label: 'ruName',
          value: 'ruCode',
        }}
        defaultOpen={!isEdit}
        value={selectedRu?.ruCode}
        defaultValue={selectedRu?.ruCode}
        defaultActiveFirstOption={false}
        onSelect={(val:any, option:any) => onRuSelect(option)}
        filterOption={
          (inputValue, option) => option?.ruName
            .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
      />
    </div>
  );
};
export default RuSection;
