/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import IcClose from '@ingka/ssr-icon/paths/cross';
import { Select } from 'antd';
import './RetailUnitSelection.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './RetailUnitSelection.module.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import flags from '../../../../common/services/flags';
import { RetailUnit } from '../../../desktop/createProject/retailUnitsTab/RetailUnits';
import { offlineData } from '../../../../common/configs/offlineData';
import { appRoutes } from '../../../../common/constants/constant';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import useCallApi from '../../../../common/services/apiService.js';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setLoader } from '../../../../store/reducers/create-proj-reducer';

const { Option } = Select;

interface SectionTitleProps {
  sectionTitle: string;
  customClass?: string | null;
}

export const SectionTitle = ({
  sectionTitle, customClass,
}: SectionTitleProps) => (
  <span className={`${styles.ruText} ${customClass}`}>{sectionTitle}</span>
);

SectionTitle.defaultProps = {
  customClass: null,
};

const RetailUnitSelection = () => {
  const [retailUnits, setRetailUnits] = useState<RetailUnit[]>([]);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const navigate = useNavigate();
  const location: any = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const { get } = useCallApi();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const isLoading = useAppSelector((state) => state.createProjReducer.isLoading);

  const getRetailUnits = () => {
    offlineData.getItem('userRetailUnits').then((userRetailData: any) => {
      setRetailUnits(userRetailData);
      offlineData.getItem('selectedRetailUnit').then((data: any) => {
        setIsEdit(location?.state?.isEdit || false);
        setSelectedRU(data);
      });
    });
  };

  useEffect(() => {
    getRetailUnits();
    localStorage.setItem('loggedOut', 'false');
  }, []);

  const onRetailUnitChange = (value: number) => {
    const selectedVal = retailUnits[value];
    setSelectedRU({ ...selectedVal, ruVal: value });
    dispatch(setLoader(true));
    if (!isNullEmptyOrUndefined(selectedVal)) {
      if (isAuthenticated === true) {
        get('enum-lookups', (enumData: any) => {
          const enumRu = enumData.find((item: any) => (item.groupName === 'RefRuCode' && item.key === selectedVal?.ruCode));
          offlineData.setItem('selectedRetailUnit', { ...selectedVal, ruVal: value, refRuCode: enumRu?.value || null });
          localStorage.setItem('selectedRetailUnit', JSON.stringify({ ...selectedVal, refRuCode: enumRu?.value || null }));
        });
      } else loginWithRedirect();
    } else {
      offlineData.setItem('selectedRetailUnit', { ...selectedVal, ruVal: value, refRuCode: null });
      localStorage.setItem('selectedRetailUnit', JSON.stringify({ ...selectedVal, refRuCode: null }));
    }
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 1200);
  };

  const onRetailUnitSave = () => {
    // checked click action from mobile hamburger menu
    if (isEdit) {
      navigate(appRoutes.dashboard, { state: isEdit });
    } else {
      navigate(isMobile ? appRoutes.HFBselect : appRoutes.dashboard);
    }
  };

  return (
    <div className={isMobile ? styles.ruWrapperMob : styles.ruWrapper}>
      {isEdit ? (
        <div className={styles.ruHeaderWrapper}>
          <Button
            className={styles.ruMobileCloseButton}
            iconOnly
            small
            type="tertiary"
            onClick={() => navigate(appRoutes.dashboard)}
          >
            <SSRIcon paths={IcClose} />
          </Button>
        </div>
      ) : null}
      <Text className={styles.ruTitle}>{useTranslate('mobile.initial.ruSelectionTitle')}</Text>
      <div className={styles.mobileContentWrapper}>
        <div className={styles.sectionWrapper}>
          <Select<number, { value: string; children: any }>
            className={styles.ruSelect}
            value={selectedRU?.ruVal}
            suffixIcon={<SSRIcon paths={IcDownArrow} />}
            placeholder={useTranslate('mobile.initial.ruPlaceholder')}
            optionFilterProp="children"
            onChange={onRetailUnitChange}
            filterOption={(input, option) => option?.children.props.children[1].props.children
              .toLowerCase().indexOf(input.toLowerCase()) >= 0}
            data-testid="selectRu"
          >
            {retailUnits?.map((retailUnit, index) => (
              <Option value={index} key={retailUnit.ruCode} data-testid={`retail-unit-${retailUnit.ruCode}`}>
                <div className={styles.ruItemWrapper}>
                  <img className="table-image-left" src={flags[retailUnit.ruCode]} alt={retailUnit.ruCode} />
                  <span id="ruName" data-testid={`retail-unit-${retailUnit.ruName}`}>{retailUnit.ruName}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <Button
        className={isMobile ? styles.countinueButtonMob : styles.countinueButton}
        text={useTranslate('common.text.continue')}
        type="emphasised"
        onClick={onRetailUnitSave}
        disabled={selectedRU === null || isLoading === true}
        data-testid="continueButton"
      />
    </div>
  );
};

export default RetailUnitSelection;
